@charset "UTF-8";
// ==========================================================================
// record
// recordページのスタイル
// ==========================================================================
.p-sgRecordItemWrap {
  &--mb77 {
    margin-bottom: 77px;
  }
  &--v {
    margin-top: 84px;
    margin-bottom: 78px;
  }
}

.sgrecordItem {
  padding: 0;
  @media #{$sp} {
    // padding: 0 15px;
  }
  &--v {
    &:nth-child(n+4) {
      margin-top: 48px;
      @media #{$sp} {
        margin-top: 27.5px;
      }
    }
    &:nth-child(n+3) {
      @media #{$sp} {
        margin-top: 27.5px;
      }
    }
  }
  &__ttl {
    height: 36px;
    top: 15px;
    font-size: 1.8rem;
    font-weight: 700;
    // background-color: #BC0010;
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
    }

    // &--black {
    //   padding: 0 35px 0 20px;
    //   top: 0;
    //   line-height: 36px;
    //   background-color: #050505;
    //   z-index: 2;
    //   box-sizing: border-box;
    //   &::before {
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     display: inline-block;
    //   }
    //   &::after {
    //     bottom: 0;
    //     border-color: #050505 transparent transparent transparent;
    //   }
    // }

    // &--red {
    //   width: calc(100% + 2px);
    //   top: 6px;
    //   background-color: #BC0010;
    //   z-index: 1;
    //   &::after {
    //     border-color: #BC0010 transparent transparent transparent;
    //   }
    // }
    
    // span {
    //   height: 100%;
    //   left: 0;
    //   position: absolute;
    //   display: inline-block;
    //   box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
    //   &::after {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     right: -16px;
    //     border-style: solid;
    //     border-width: 36px 16px 0 0;
    //     display: inline-block;
    //     position: absolute;
    //   }
    // }
  }

  &__image {
    border-radius: 10px;
    overflow: hidden;
    @media #{$sp} {
      margin-bottom: 18px;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}




.sgvSection {
  // padding: 62px 0 85px;
  padding: 62px 45px 85px;
  margin: 0 -45px;
  background-size: cover;
  background-position: center;
  background-image: url(../images/sgv_best5.png);
  @media #{$sp} {
    padding: 90px 18px 82px;
    margin: 0 -18px;
  }
}

.sgvWrap {
  padding:0 15px;
  margin-top: 74px;
  @media #{$sp} {
    padding: 0 18px;
  }
}

.sgvItem {
  max-width: 162px;
  @media #{$sp} {
    max-width: 100%;
    margin-bottom: 36px;
  }
  &:nth-child(n+2) {
    @media #{$sp} {
      max-width: calc(50% - 9px);
    }
  }
  &__image {
    // max-width: 162px;
    max-height: 138px;
    // border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    // overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  &__ranking {
    height: 28px;
    padding: 0 11px;
    left: 0;
    bottom: -8px;
    font-size: 1.4rem;
    line-height: 28px;
    color: #fff;
    background-color: #050505;
    display: inline-block;
    // position: relative;
    position: absolute;
    &::after {
      content: "";
      // width: 100%;
      width: 0;
      height: 0;
      // right: -7.5px;
      right: -7px;
      border-style: solid;
      // border-width: 28px 7.5px 0 0;
      border-width: 28px 8px 0 0;
      border-color: #050505 transparent transparent transparent;
      display: inline-block;
      position: absolute;
    }
  }

  &__cnt {
    padding-bottom: 15px;
    border-bottom: 1px solid #CBCBCA;
  }

  &__name {
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__victory {
    margin-left: auto;
    font-size: 1.6rem;
    font-weight: 700;
  }
}



.recordTtl {
  padding: 44px 10px 18.5px;
  border-bottom: 1px solid #CBCBCA;
  font-size: 1.8rem;
  font-weight: 700;
  @media #{$sp} {
    padding: 0 10px 12px;
  }
}

.p-recordContent {
  &__cnt {
    padding: 16.5px 10.5px 18.5px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;
    &--v {
      padding: 0;
      @media #{$sp} {
        padding: 0 15px;
      }
    }
  }

  &__ttl {
    border-bottom: 1px solid #CBCBCA;
    padding-bottom: 18.5px;
    margin-bottom: 16.5px;
    font-size: 1.6rem;
    font-weight: 700;
    @media #{$sp} {
      padding-bottom: 14.5px;
      margin-bottom: 14.5px;
    }
  }

  &__racer {
    margin-bottom: 12px;
    font-size: 1.6rem;
    font-weight: 700;
    @media #{$sp} {
      margin-bottom: 9px;
    }
  }

  &__date {
    // border-bottom: 1px solid #CBCBCA;
    padding-bottom: 12px;
    font-size: 1.2rem;
  }

  &__stadium {
    padding-bottom: 18px;
    border-bottom: 1px solid #CBCBCA;
  }
}

.p-sgRecordTtl {
  height: 36px;
  // padding: 10px 20px;
  // padding: 10px 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  // bottom: -15px;
  bottom: -29px;
  color: #fff;
  // background-color: #000;
  background-color: #BC0010;
  display: inline-block;
  position: relative;
  box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
  z-index: 2;
  @media #{$sp} {
    // height: 30px;;
    height: 28px;
    // bottom: -18px;
    bottom: -22px;
    font-size: 1.3rem;
  }

  &::before {
    content: "";
    width: 5px;
    height: 100%;
    background-color: #BC0010;
    display: inline-block;
  }
  
  &::after {
    content: "";
    // width: 100%;
    // height: 100%;
    // bottom: -6px;
    // left: 0;
    // background-color: #BC0010;
    // display: inline-block;
    // position: absolute;
    // z-index: -1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 36px 16px 0 0;
    top: 0;
    border-color: #BC0010 transparent transparent transparent;
    position: absolute;
    @media #{$sp} {
      border-width: 28px 12px 0 0;
    }
  }

  span {
    max-height: 100%;
    // padding: 10px 20px;
    padding: 7px 20px;
    left: -5px;
    bottom: 18px;
    background-color: #000;
    position: relative;
    z-index: 2;
    box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
    @media #{$sp} {
      // width: 100%;
      // width: calc(100% - 5px);
      height: 100%;
      padding: 7px 15px;
      bottom: 15px;
      display: inline-block;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 16px 0 0;
      top: 0;
      right: -16px;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      @media #{$sp} {
        border-width: 28px 12px 0 0;
        right: -12px;
      }
    }
  }
}