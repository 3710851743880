@charset "UTF-8";
// ==========================================================================
// btn
// ボタンのスタイル
// ==========================================================================

.c-btnWrap {
	width: 100%;
}

.c-btn {
	// max-width: 310px;
	// max-width: 250px;
	width: 100%;
	// padding: 20px 25px;
	border-radius: 25px;
	// border-radius: 50%;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1;
	// color: #fff;
	// background-color: #009FE3;
	display: block;
	text-align: center;
	// margin: 0 auto;
	transition: all .3s ease-in-out 0s;
	box-sizing: border-box;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	&--border {
		border: 1px solid #CBCBCA;
		color: #302E2C;
	}

	// a {
	// 	color: #fff;
	// }
}


.moreBtn {
	width: 110px;
	height: 34px;
	border-radius: 60px;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 34px;
	text-align: center;
	color: #000;
	background-color: #F2F2F2;
}