
.raceTabs {
  border-bottom: 2px solid #BC0010;

  .nav-link {
    padding: 10px 20px;
    color: #858585;
    font-weight: bold;
    border: none;
    @media only screen and (min-width: 768px) {
      padding: 10px 40px;
    }
  }
  .nav-link.active {
    background-color: #BC0010;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .nav-link:focus, .nav-link:hover {
    border: none;
  }
}

.raceTabsContent {
  margin: 20px 0;
}

.racePredict {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin: 30px 0;
  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
  }
  @media #{$sp} {
    overflow-x: scroll;
  }

  &__title {
    margin-bottom: 20px;
    @media only screen and (min-width: 1025px) {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &__nerai {
    display: flex;
    align-items: center;
    padding: 10px;

    &:not(:last-child) {
      @media only screen and (min-width: 1025px) {
        margin-right: 40px;
      }
    }

    &__itemParentGroup {
      display: flex;
      justify-items: center;
    }

    &__itemGroup {
      display: flex;
      align-items: center;

      div:first-of-type {
        margin-left: 10px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      :not(:last-child) {
        margin-right: 5px;
      }
    }

    &__item {
      display: inline;
      padding: 1px 5px;
      border-radius: 5px;
      font-size: 10px;
      font-weight: bold;
      @media only screen and (min-width: 1025px) {
        font-size: 12px;
      }
    }


    &__weather {
      border-right: 1px solid #CBCBCA;
      margin: -10px 0;
      padding: 10px 10px 10px 0;
    }
    &--sun {
      background-color: #FFE6D4;
    }

    &--rain {
      background-color: #D8ECF5;
      margin-top: 8px;
      @media only screen and (min-width: 1025px) {
        margin-top: 0;
      }
    }

  }
}


.oddsTable {
  width: 100%;

  &__oddIcon {
    width: 15px;
  }

  tr {
    border-bottom: 1px solid #CBCBCA;
    &:nth-child(even) {
      background-color: #F4F4F4;
    }
  }

  // th {
  //   border-bottom: 1px solid #CBCBCA !important;
  // }
  
  th,td {
    // min-width: 48px;
    max-width: 185px;
    border-top: 1px solid #CBCBCA;
    border-right: 1px solid #CBCBCA;
    // white-space: nowrap;
    // @media #{$sp} {
    //   min-width: 24px;
    // }
    @media #{$sp} {
      font-size: 1rem;
      white-space: nowrap;
    }
    &:first-child {
      border-left: 1px solid #CBCBCA;
    }
  }

  th {
    height: 35px;
    border-bottom: 1px solid #CBCBCA !important;
    @media #{$sp} {
      padding: 0.75rem 3px;
    }
  }

  td {
    // padding: 6px 0;
    max-height: 88px;
    text-align: center;

    @media #{$sp} {
      padding:0.75rem 3px
    }
    p {
      height: 25%;
      font-size: 1rem;
      // line-height: 22px;
      &:nth-child(even) {
        background-color: #F4F4F4;
      }
    }

    &:first-child {
      background-color: #FFE6D4;
      // min-width: 30px
    }
    &:nth-child(2) {
      background-color: #D8ECF5;
      // min-width: 30px;
    }
    &:nth-child(3) {
      width: 10px;
    }
    &:nth-child(4) {
      width: 200px;
      @media #{$sp} {
        width: auto;
      }
    }
    &:nth-child(6) {
      @media #{$sp} {
        width: 15px;
      }
    }
    &:last-child {
      @media #{$sp} {
        width: 25px;
      }
    }
  }

  &__head {
    // max-width: 185px;
    font-size: 1.2rem;
    text-align: center;
    background-color: #302E2C;
    span {
      font-size: 1rem;
      display: block;
    }
  }
}

.oddsTableWeather {
  &__sunny {
    background-color: #FFE6D4;
  }

  &__rain {
    background-color: #D8ECF5;
  }
}


.subOddsTable {
  width: 100%;
  margin-top: 10px;

  tr {
    border-bottom: 1px solid #CBCBCA;
  }

  th {
    border-bottom: 1px solid #CBCBCA !important;
    font-weight: bold;
  }
  th,td {
    border-top: 1px solid #CBCBCA;
    border-right: 1px solid #CBCBCA;
    @media #{$sp} {
      // min-width: 24px;
      font-size: 10px;
      padding: 0.75rem 2px;
    }
    &:first-child {
      border-left: 1px solid #CBCBCA;
    }
  }

  th {
    height: 23px;
  }

  td {
    // padding: 6px 0;
    max-height: 88px;
    text-align: center;

    p {
      height: 25%;
      font-size: 1rem;
      // line-height: 22px;
      &:nth-child(even) {
        background-color: #F4F4F4;
      }
    }
  }

  td:nth-child(odd){
    // min-width: 20px;
    font-weight: bold;
  }


  td:nth-child(even){
    min-width: 80px;
    @media #{$sp} {
      min-width: auto;
    }
  }

  &__head {
    // max-width: 185px;
    font-size: 1.2rem;
    text-align: center;
    background-color: #302E2C;
    span {
      font-size: 1rem;
      display: block;
    }
  }
}