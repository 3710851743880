.sitemapContent {
  padding: 36px 35px 11px 31px;
  border-radius: 10px;
  margin-bottom: 25px;
  background-color: #F4F4F4;
  &__headline {
    // margin-right: 36px;
    margin-bottom: 28px;
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    // display: inline-block;
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 16.5px;
      font-size: 1.4rem;
      text-align: center;
      display: block;
    }
    &::after {
      content: "";
      width: 100%;
      top: 50%;
      height: 0.5px;
      left: 0;
      background-color: #858585;
      display: inline-block;
      transform: translateY(-50%);
      position: absolute;
      @media #{$sp} {
        position: relative;
        transform: inherit;
      }
    }

    span {
      display: inline-block;
      position: inherit;
      z-index: 1;
      &::after {
        content: "";
        width: 130%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f4f4f4;
        display: inline-block;
        position: absolute;
        z-index: -1;
        @media #{$sp} {
          width: 100%;
        }
      }
    }
  }
}


.sitemapMenu {
  &__item {
    margin-right: 25px;
    margin-bottom: 21px;
    font-size: 1.4rem;
    font-weight: 700;
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 12px;
      font-size: 1.3rem;
    }
    a {
      color: #302E2C;
    }
  }
}