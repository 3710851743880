@charset "UTF-8";
// ==========================================================================
// LIVE放送
// ==========================================================================

// .live-leftWrap {
//   max-width: calc(50% - 13px);
//   border-radius: 10px;
//   padding: 20px 20px 40px;
//   box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
//   @media #{$sp} {
//     padding: 20px 20px 30px;
//     margin-bottom: 20px;
//   }
// }

// .liveMenu {
//   border-top: 1px solid #CBCBCA;
//   border-bottom: 1px solid #CBCBCA;
//   margin: 15px auto 18.5px;
//   @media #{$sp} {
//     margin: 20px auto 18.5px;
//   }
  
//   &__item {
//     width: calc(100% / 3);
//     // padding: 8px 0 10.5px;
//     font-size: 1.2rem;
//     font-weight: 700;
//     line-height: 1;
//     text-align: center;
//     position: relative;
    
//     &::after {
//       content: "";
//       transition: all .3s;
//       display: block;
//     }

//     &:hover {
//       &::after {
//         content: "";
//         width: 100%;
//         height: 3px;
//         bottom: -1px;
//         background-color: #BC0010;
//         display: block;
//         position: absolute;
//       }
//     }

//     &--current {
//       padding: 8px 0 10.5px;
//       color: #302E2C;
//       // position: relative;
//       &::after {
//         content: "";
//         width: 100%;
//         height: 3px;
//         bottom: -1px;
//         background-color: #BC0010;
//         display: block;
//         position: absolute;
//       }
//     }

//     a {
//       padding: 8px 0 10.5px;
//       color: #858585;
//       transition: all .3s;
//       display: block;
//       &:hover {
//         color: #302E2C;
//       } 
//     }

//     i {
//       margin-left: 5px;
//     }
//   }
// }


// .liveTime {
//   margin-bottom: 15px;
//   font-size: 1.2rem;
//   color: #302E2C;
// }

// .last10TableWrap {
//   max-width: calc(50% - 13px);
//   border-radius: 10px;
//   padding: 20px 20px 40px;
//   box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
// }

// .last10Table {
//   width: 100%;

//   tr {
//     &:nth-child(even) {
//       background-color: #F4F4F4;
//     }
//   }

//   th,td {
//     text-align: center;
//   }

//   td {
    
//   }
// }

// .final {
//   color: #fff;
//   background-color: #24B6F7;
// }

// .semifinal {
//   color: #fff;
//   background-color: #BC0010;
// }


// .liveTable {
//   width: 100%;

//   tr {
//     border-bottom: 1px solid #CBCBCA;
    
//     &:first-child {
//       border-top: 1px solid #CBCBCA;
//     }
//   }

//   th,td {
//     border-right: 1px solid #CBCBCA;
//     text-align: center;
//     position: relative;
//   }

//   th {
//     background-color: #F4F4F4;
//   }

//   // td {
//   //   &:not(:nth-child(n+2)) {
//   //     font-weight: 700;
//   //   }
//   // }

//   &__race {
//     font-size: 1.2rem;
//     font-weight: 700;
//     text-align: center;
//     color: #fff;
//     background-color: #302E2C;
//   }

//   &__cnt {
//     position: relative;
//     &::after {
//       content: "";
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       display: block;
//       position: absolute;
//       z-index: 0;
//     }

//     &--fix {
//       &::after {
//         background-color: #E0E0E0;
//       }
//     }

//     &--thisround {
//       &::after {
//         background-color: #BC0010;
//         opacity: .1;
//       }
//     }

//     &--onsale {
//       &::after {
//         background-color: #31B7F4;
//         opacity: .1;
//       }
//     }

//     span {
//       position: relative;
//       z-index: 2;
//     }
//   }
// }


.liveWrap {
  padding: 0 45px;
  background-size: cover;
  background-repeat: no-repeat;
  @media #{$sp} {
    padding: 0;
  }
}

.liveTtl {
  margin-bottom: 52px;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  @media #{$sp} {
    margin-bottom: 37px;
    font-size: 1.6rem;
  }

  &__time {
    margin-left: 10px;
    @media #{$sp} {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}

.liveTopWrap {
  // padding: 0 18px;
  margin-left: -15px !important;
  margin-right: -15px !important;
  @media #{$sp} {
    padding: 0;
    margin: auto !important;
  }
}

.liveTabMenu {
  margin-left: 24px;
  @media #{$sp} {
    margin-left: 18px;
  }

  &__item {
    padding: 5px 20px 7px;
    border-radius: 5px 5px 0 0;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #858585;
    @media #{$sp} {
      padding: 10px;
      font-size: 1.3rem;
    }

    &:not(:last-child) {
      margin-right: 3px;
    }
    
    &--current {
      background-color: #BC0010;
    }

    a {
      color: #fff;
    }
  }
}

.liveNestaBtn {
  padding: 5px 25px;
  border-radius: 20px;
  font-size: 1.2rem;
  color: #302E2C;
  background-color: #fff;
  @media #{$sp} {
    padding: 5px 10px;
  }
}

.liveVideo {
  max-width: 100%;
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  background:#000;
  position: relative;
  border-radius: 10px 10px 0 0;
  // max-height: 234px;
  overflow-x: hidden;
  @media #{$sp} {
    // max-height: 197px;
  }

  &>div.bc-player-default_default,&>div.vjs-paused,&>div.vjs-pip-inactive {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &>div.vjs-pip-y-bottom {
    width: 406px;
    height: 228px;
    // width: 100%;
    // height: 100%;
    // position: relative;
    // position: absolute;
    position: fixed;
  }

  // &>div.vjs-pip-active {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  // }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    @media #{$sp} {
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }
}


.pip-toggle {
  // max-width: 100%;
  width: 100% !important;
  // height: 0 !important;
  height: 100% !important;
  // padding-bottom: 56.25%;
  padding-bottom: 0;
  background:#000;
  position: relative;
  border-radius: 10px 10px 0 0;
  @media #{$sp} {
    // max-height: 197px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    @media #{$sp} {
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }
}

.bc-player-default_default .vjs-big-play-button:active, .bc-player-default_default .vjs-big-play-button:focus, .bc-player-default_default:active .vjs-big-play-button, .bc-player-default_default:hover .vjs-big-play-button {
  background-color: rgba(0,0,0,.8);
}

.bc-player-default_default .vjs-progress-control .vjs-progress-holder {
  display: none;
}

.pipBtn {
  padding: 8px 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  border: 1px solid #302E2C;
  border-radius: 5px;
  background-color: #fff;
  outline: 0;
  @media #{$sp} {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}

.liveInfo {
  padding: 15px 17px;
  border-radius: 0 0 10px 10px;
  // margin-bottom: 45px;
  margin-bottom: 0;
  background-color: #fff;
  @media #{$sp} {
    padding: 12px 20px 20px;
    margin-bottom: 40px;
  }

  &__txt {
    font-size: 1rem;
  }
}

.liveMovieWrap {
  // border-radius: 10px;
  // background-color: #fff;
  @media #{$sp} {
    margin-bottom: 24px;
  }
}

.live-tabCnt {
  padding: 18px 25px;
  border-radius: 10px;
  background-color: #fff;
}

.live-tabCntRace {
  margin-bottom: 14px;
  overflow-x: auto;

  &__item {
    // width: 28px;
    min-width: 30px;
    // height: 29px;
    // border-radius: 20px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    font-size: 1rem;
    font-weight: 700;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media only screen and (max-width: 1200px) {
      padding: 4px 20px;
      margin-bottom: 5px;
    }
    
    @media #{$sp} {
      padding: 4px 20px;
    }

    &:not(:last-child) {
      // margin-right: 8px;
      margin-right: 5px;
    }

    a {
      padding: 5px 3px;
      color: #302E2C;
    }

    &--current {
      background-color: #BC0010;

      a {
        color: #fff;
      }
    }
  }
}

.live-recentRank {
  font-size: 1.8rem !important;
  background-color: #F4F4F4;
}

.live-raceBtn {
  &__item {
    max-width: calc(50% - 10px);
    width: 100%;
    padding: 5.5px 0;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background-color: #302E2C;
    display: block;
  }
}

.liveVideo {
  background: #000;
}

.voteInfoWrap {
  margin-top: 26px;
  // margin-bottom: 18px;
  margin-bottom: 0;
  @media only screen and (max-width: 1200px) {
    margin-top: 0;
  }
  @media #{$sp} {
    margin-bottom: 20px;
  }
}

.voteInfo {
  padding: 25px 18px;
  // border-radius: 10px 10px 0 0;
  border-radius: 0;
  margin-bottom: 0;
  @media #{$sp} {
    padding: 20px 14px;
  }
 
  &__label {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    font-size: 1.8rem;
    font-weight: 700;
    color: #fff;
    background-color: #24B6F7;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sp} {
      width: 56px;
      height: 56px;
      font-size: 1.6rem;
    }
  }

  &__time {
    width: 70%;
    height: 60px;
    padding: 12px 17px;
    margin-left: 10px;
    border-radius: 10px;
    // margin-left: 12px;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #302E2C;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sp} {
      // max-width: 95px;
      max-width: inherit;
      height: 56px;
      font-size: 2.4rem;
    }
  }

  &__deadline {
    padding: 11px 0;
    border-radius: 10px 10px 0 0;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
    background-color: #302e2c;
    color: #ffffff;
    // margin-bottom: 12px;
    // font-size: 1.4rem;
    // font-weight: 700;
    // line-height: 1;
    @media #{$sp} {
      font-size: 1.1rem;
    }
  }

  &__btn {
    padding: 8px 42px;
    border-radius: 15px;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #858585;
    @media #{$sp} {
      padding: 7px 40px;
      font-size: 1.3rem;
    }
  }

  &__comment {
    padding: 12px 0;
    border-radius: 0 0 10px 10px;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
    background-color: #F4F4F4;
  }
}

.testdriveTable {
  border-radius: 10px;
  // margin-bottom: 30px;
  margin-bottom: 0;
  overflow: hidden;
  @media #{$sp} {
    margin-bottom: 20px;
  }

  &__after {
    // content: "";
    width: 100%;
    height: 20px;
    background-color: #fff;
    display: block;
  }

  td {
    border-right: 1px solid #CBCBCA;
    border-bottom: 1px solid #CBCBCA;
    text-align: center;
  }

  &__ttl {
    // padding: 7px 0;
    padding: 11px 0;
    // border-radius: 10px 10px 0 0;
    font-size: 1rem;
    // font-weight: 700;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    color: #fff;
    background-color: #302E2C;
  }
}

// バナー
.liveBannerWrap {
  // width: 100%;
  margin-top: 30px;
  // @media only screen and (max-width: 1200px) {
  //   width: 50%;
  // }
}

.liveBanner {
  width: 100%;
  display: block;
  @media only screen and (max-width: 1199px) {
    width: calc(50% - 10px);
  }
  @media #{$sp} {
    width: 100%;
  }

  &:not(:first-child) {
    margin-top: 20px;
    @media only screen and (max-width: 1199px) {
      margin-top: 0;
    }
    @media #{$sp} {
      margin-top: 10px;
    }
  }
}

.live-mainWrap {
  margin-top: 60px;
  @media #{$sp} {
    margin-top: 37px;
  }
}

.live-mainCnt {
  padding: 30px 20px 58px;
  border-radius: 10px;
  background-color: #fff;
  @media #{$sp} {
    padding: 30px 0 58px;
    border-radius: 0;
  }
}

.liveTableWrap {
  // padding: 0 0 38px;
  padding: 0;
  border-radius: 10px;
  background-color: #fff;
  @media #{$sp} {
    // padding-left: 18px;
    padding: 0px;
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    @media #{$sp} {
      height: 15px;
    }
  }

  &::-webkit-scrollbar-track {
    @media #{$sp} {
      border-radius: 5px;
      background: #eee;
    }
  }

  &::-webkit-scrollbar-thumb {
    @media #{$sp} {
      border-radius: 5px;
      background: #666;
    }
  }
}

/* 出場選手 */
.liveRacer {
  border-top:1px solid #cbcbca;
  border-left:1px solid #cbcbca;

  a {
    color:#212529;
    &:hover {
      color: #0056b3;
    }
  }

  dt {
    border-bottom:1px solid #cbcbca;
    text-align:center;
    padding:0.3em 0 0.3em 0;
    background:#f4f4f4;
  }

  dd {
    border-bottom:1px solid #cbcbca;
    border-right:1px solid #cbcbca;
    padding:0.3em 0 0.3em 0.3em;
  }
}


.live-mainTab01 {
  padding-bottom: 20px;
  border-bottom: 1px solid #302E2C;
  margin-bottom: 14px;
  overflow-x: auto;
  @media #{$sp} {
    padding: 0 18px 20px;
    // overflow-x: auto;
  }

  &__item {
    // min-width: 62px;
    // padding: 0 22px;
    border-radius: 20px;
    text-align: center;
    background-color: #858585;
    @media #{$sp} {
      // min-width: 59px;
      // padding: 1px 14px;
      font-size: 1.1rem;
    }

    &:not(:first-child) {
      margin-left: 9px;
    }
  
    &--current {
      background-color: #BC0010;
    }

    a {
      padding: 0 22px;
      color: #fff;
      display: block;
      @media #{$sp} {
        padding: 1px 14px;
      }
    }
  }
}


.live-mainTab02 {
  padding: 0 35px;
  border-bottom: 2px solid #BC0010;
  margin-bottom: 35px;
  @media #{$sp} {
    padding: 0 15px;
  }

  &__item {
    // width: 20%;
    width: 25%;
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    @media #{$sp} {
      // font-size: 1rem;
      font-size: 1.3rem;
    }

    a {
      color: #858585;
      display: block;
    }

    &--current {
      background-color: #BC0010;

      a {
        color: #FFFFFF; 
      }
    }
  }
}

.live-mainTab03 {
  margin-bottom: 18px;
  overflow-x: auto;

  @media #{$sp} {
    padding: 0 20px;
  }

  &__item {
    padding: 3.5px 0;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: #302E2C;
    background-color: #F4F4F4;
    white-space: nowrap;
    @media #{$sp} {
     padding: 3.5px 5px; 
    }

    &:not(:last-child) {
      margin-right: 10px;
      @media #{$sp} {
        margin-right: 5px;
      }
    }

    a {
      color: #302E2C;
      display: block;
    }

    &--current {
      // background-color: #24B6F7;
      background-color: #bc0010;
      a {
        color: #fff;
      }
    }
  }
}

.live-mainTab03--4 .live-mainTab03__item {
  width: calc(25% - 10px);
}

.live-mainTab03--8 .live-mainTab03__item {
  width: calc(11% - 9px);
  @media #{$sp} {
    width: auto;
  }
}


.liveTable {
  width: 100%;
  border-collapse: collapse;
  border:1px solid #cbcbca;
  margin-bottom: 20px;
  table-layout: fixed;
  word-break: break-all;
  @media #{$sp} {
    max-width: 800px;
    min-width: 360px;
    // width: 200%;
  }

  &-ninki {
    @media #{$sp} {
      min-width:600px;
    }
  }

  &-23rentan {
    @media #{$sp} {
      min-width: 700px;
    }
  }

  tr {
    border-bottom: 1px solid #fff;
  }

  th,td {
    // border-top: 1px solid #858585;
    // border-right: 1px solid #cbcbca;
    // border-bottom: 1px solid #858585;
    border: 1px solid #cbcbca;
    text-align: center;
    white-space: nowrap;

    &:first-child {
      // border-top: 1px solid #cbcbca;
      // border-left: 1px solid #cbcbca;
      border: 1px solid #cbcbca;
    }
  }

  th {
    color: #fff;
    background-color: #302E2C;
    @media #{$sp} {
      padding: 5px;
    }
  }

  td {
    // &::before {
    //   content: "";
    //   display: block;
    // }
    @media #{$sp} {
      font-size: 1.2rem;
    }

    &.raceNum--01 {
      border-left: 1px solid #cbcbca;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #cbcbca;
        }
      }
    }

    &.raceNum--02 {
      border-left: 1px solid #302e2c;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #302e2c;
        }
      }
    }

    &.raceNum--03 {
      border-left: 1px solid #eb5353;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #eb5353;
        }
      }
    }

    &.raceNum--04 {
      border-left: 1px solid #3a8aec;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #3a8aec;
        }
      }
    }

    &.raceNum--05 {
      border-left: 1px solid #f6e92a;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #f6e92a;
        }
      }
    }

    &.raceNum--06 {
      border-left: 1px solid #5c9e00;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #5c9e00;
        }
      }
    }

    &.raceNum--07 {
      border-left: 1px solid #f90;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #f90;
        }
      }
    }

    &.raceNum--08 {
      border-left: 1px solid #ef6ea6;
      &::before {
        @media only screen and (max-width: 1200px) {
          border-left: 1px solid #ef6ea6;
        }
      }
    }
  }

  a {
    color: #302e2c;
  }


  &__name {
    font-size: 1.3rem;
    font-weight: 700;
  }

  &__image {
    max-width: 68px;
    height: 68px;
  }

  // &__average {
  //   font-size: 2.5rem;
  // }

  // &__status {
  //   font-size: 3.5rem;
  // }

  // &__percent {
  //   font-size: 2.5rem;
  // }

  &__axis {
    padding: 0 16px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    font-size: 1rem;
    color: #302E2C;
    background-color: #fff;

    &--current {
      color: #fff;
      background-color: #BC0010;
    }
  }
}

.liveTable a:hover, .liveTable a:hover p.live-recentTable__name {
  color: #0056b3;
}

.liveFixedCell {
  @media only screen and (max-width: 1200px) {
    position: sticky;
    left: 0;
  }
  
  &::before {
    @media only screen and (max-width: 1200px) {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      width: 100%;
      height: 100%;
      border-left: 1px solid #cbcbca;
    }
  }
}

.oddsNum {
  font-size:1.6rem;
}

/* 高低オッズの背景色 */
.RateNone {
  background-color:#ddd;
}
.RateHigh {
  background-color: #D1FCE2;
}
.RateLow {
  background-color: #FEEDC0;
}

/* 表の見方 */
a.collapseBtn:after {
  display: inline-block;
  margin-left: 0.5em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
a.collapseBtn[aria-expanded=true]:after {
  content: "\f00d";
}
a.collapseBtn[aria-expanded=false]:after {
  content: "\f078";
}

/* 出走表オッズの列幅 */
.colgroupWidth-sm {
  width:38px;
  @media #{$sp} {
    width:24px;
  }
}
.colgroupWidth-md {
  width:66px;
}
.colgroupWidth-name {
  width:200px;
}


.lineHeight-reset {
  line-height: 1.2;
}

.live-recentTable {
  width: 100%;
  @media #{$sp} {
    width: 200%;
  }

  th,td {
    border-right: 1px solid  #CBCBCA;
    border-bottom: 1px solid #CBCBCA;
    text-align: center;
    white-space: nowrap;

    &:first-child {
      border-left: 1px solid #CBCBCA;
    }
  }

  th {
    color: #fff;
    background-color: #302E2C;
  }

  td {
    min-height: 24px;
    padding: 5px 0;
    font-size: 1rem;
    line-height: 1.2;
  }

  &__image {
    // width: 68px;
    // height: 68px;
    // width: 36px;
    // height: 36px;
    width: 65px;
    height: 65px;
    margin: 10px;
    // padding: 10px 0 10px 10px;
    margin-left: 10px;
    @media #{$sp} {
      width: 50px;
      height: 50px;
    }    
    img {
      width: auto;
      height: 100%;
      border-radius: 5px;
    }
  }

  &__name {
    // margin-left: 20px;
    margin-left: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: left;
    color: #302E2C;
    white-space: nowrap;
    display: block;
    @media #{$sp} {
      margin-left: 16px;
      margin-right: 10px;
    }

    span {
      font-size: 1.2rem;
      display: block;
      font-weight: normal;
      line-height: 1.2;
    }
  }

  &__average {
    // font-size: 2.5rem !important;
    font-size: 1.8rem !important;
  }

  &__status {
    // font-size: 3.5rem !important;
    font-size: 2.4rem !important;
  }

  &__percent {
    // font-size: 2.5rem !important;
    font-size: 1.8rem !important;
  }

  &__text {
    &--fs18 {
      font-size: 1.8rem !important;
      background-color: #F4F4F4;
    }
  }
}

.fa-arrow-right {
  &--up {
    transform: rotate(-45deg);
  }

  &--down {
    transform: rotate(45deg);
  }
}



.live-oddsTable {
  margin-bottom: 20px;
  &__name {
    font-size: 1.6rem;
    font-weight: 700;
    p {
      margin: auto;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}


.colgroupWidth-xs {
  width:19px;
}