@charset "UTF-8";
// ==========================================================================
// Mixin
// グローバルなmixinはここに格納します。それ以外はそのscssファイルのみで完結。
// ==========================================================================


// -----------------------------------------------------------------
// base font setting
// -----------------------------------------------------------------

// spのベースフォント
@mixin spBaseFont {
	// font-family: $fontHirakaku;
	font-size: 1.5rem;
	line-height: 1.5 !important;
}

// pcのベースフォント
@mixin pcBaseFont {
	// font-family: $font;
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.86 !important;
}

// 英字のフォント
@mixin en {
	// font-family: $fontEn;
	letter-spacing: .1em;
}

// 明朝体のフォント
@mixin mincho {
	@media #{$sp} {
		// font-family: $fontMinchoSp;
	}
	@media #{$tbpc} {
		// font-family: $fontMincho;
	}
}


// -----------------------------------------------------------------
// レイアウト関連
// -----------------------------------------------------------------

// margin, padding リセット
@mixin reset {
	padding: 0;
	margin: 0;
}

// コンテナ
@mixin containerLarge($t: 0, $b: 0) {
	box-sizing: border-box;
	padding: 0 $pcSpace;
	max-width: 132rem + $pcSpace * 2;
	margin: $t auto $b;
	width: 100%;
}

// inline-blockレイアウト
@mixin ib($value) {
	text-align: $value;
	letter-spacing: -.40em;
}

@mixin ibChild {
	display: inline-block;
	letter-spacing: normal;
}

// tableレイアウト
@mixin table($value) {
	display: table;
	width: $value;
}

@mixin tableCell($value) {
	display: table-cell;
	vertical-align: $value;
}

// overflow型cleafix
@mixin flow($value) {
	width: $value;
	overflow: hidden;
}

// 背景画像型サムネイルの設定
@mixin imgBg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

// clearfix(Compass使わないのでここに書きます)
@mixin clearfix {
	zoom: 1;
	
	&:before,
	&:after {
		content: "";
		display: table;
	}
	
	&:after {
	clear: both;
	}
}

// リンクアローのデフォルト設定
@mixin linksArrow($t: 0, $r: 0, $b: 0, $l: 0) {
	content: "";
	display: block;
	position: absolute;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
	margin: auto;
}

// 背景アイコン
@mixin icon($t: 0, $r: 0, $b: 0, $l: 0) {
	position: absolute;
	display: block;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
	margin: auto;
}


// -----------------------------------------------------------------
// アニメーション
// -----------------------------------------------------------------

//アルファアニメーション
@mixin animeAlpha {
	transition: opacity 0.2s ease-out;
}
@mixin animeAlpha3 {
	transition: opacity 0.4s ease-out;
}

@mixin animeAlphaHover($value) {
	opacity: $value / 100;
	filter:alpha(opacity=$value);
}

// 背景アニメーション
@mixin animeBg {
	transition: background 0.2s ease-out;
}

// カラーアニメーション
@mixin animeColor {
	transition: color 0.2s ease-out;
}

// allアニメーション
@mixin animeAll {
	transition: all 0.3s ease-out;
}

// ボーダーアニメーション
@mixin animeBdr {
	transition: border 0.2s ease-out;
}


// -----------------------------------------------------------------
// その他
// -----------------------------------------------------------------

// プレースホルダ
@mixin placeholderColor($value) {
	&:placeholder-shown {
		color: $value;
		line-height: 1.75;
	}
	&::-webkit-input-placeholder {
		color:$value;
		line-height: 1.75;
	}
	&:-moz-placeholder {
		color:$value;
		opacity: 1;
		line-height: 1.75;
	}
	&::-moz-placeholder {
		color:$value;
		opacity: 1;
		line-height: 1.75;
	}
	&:-ms-input-placeholder {
		color:$value;
		line-height: 1.75;
	}
}

