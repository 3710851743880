.h-header {
  width: 100%;
  background-color: #302E2C;
  position: fixed;
  z-index: 99;
  @media #{$sp} {
    // display: none;
    background-color: #141313 !important;
  }
}

.h-headerInner {
  max-width: 1400px;
  // height: 80px;
  height: 58px;
  margin: 0 auto;
  @media #{$sp} {
    // height: 58px;
    // padding: 0 10px 0 15px;
    padding: 0;
  }

  .navbar {
    height: 100%;
  }
}

.h-headerLogo {
  @media #{$sp} {
    margin: 0 auto;
  }
}

.h-headerMenu {
  @media #{$sp} {
    // display: none;
    margin-bottom: 24px;
  }

  &__item {
    font-size: 1.4rem;
    @media #{$sp} {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #CBCBCA;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1;
      display: block;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    a {
      color: #fff;
      @media #{$sp} {
        padding: 10px 23px !important;
        color: #302e2c !important;
        display: block;
      }
    }
  }
}

.h-headerJoin {
  a {
    // width: 268px;
    // width: 180px;
    max-width: 180px;
    width: 100%;
    // max-height: 36px;
    padding: 11px 27px;
    margin-bottom: 0 !important;
    // height: 40px;
    // padding: 13px 0;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: #fff;
    // background-color: #BC0010;
    @media #{$sp} {
      // display: none !important;
    }
  }
}

.h-headerRight {
  &.is-open {
    .h-headerJoin {
      display: none !important;
    }
  }
  // i {
  //   padding: 10px;
  //   border-radius: 50%;
  //   margin-left: 15px;
  //   font-size: 1.8rem;
  //   background-color: #fff;
  // }
}


// .hamMenuWrap {
//   display: flex;
//   align-items: center;
// }

// .hamMenu {
//   width: 14px;
//   height: 12px;
//   &__border {
//     // width: 14px;
//     width: 100%;
//     height: 2px;
//     background-color: #fff;
//     display: block;
//     &:nth-child(1) {
//       margin-bottom: 3px;
//     }
//     &:nth-child(2) {
//       margin-bottom: 3px;
//     }
//   }

//   &__txt {
//     margin-left: 5px;
//     font-size: 1.4rem;
//     font-weight: 400;
//     line-height: 1;
//     color: #fff;
//   }
// }

.navbar-toggler{color: #ffffff;
  font-size: 2.0rem;
  padding: 0;
  border: none;
}
.navbar-dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M6 6L 24 24M24 6L6 24'/%3E%3C/svg%3E");
}



.gsc-control-cse {
  padding: 0 !important;
  border: 0 !important;
  background-color: rgba(225,225,225,0) !important;
}

.gsc-input {
  height: 36px;
  border: 0;
  padding-right: 0 !important;
  // transition: opacity .25s ease .05s,width 0s linear 0s;
  // opacity: 0;
  display: none;
  @media #{$sp} {
    height: auto;
    display: block;
  }

  &.is-open {
    width: 268px;
    // opacity: 1;
    display: block;
    // opacity: 1;
    // transition: opacity .25s ease .3s,width 0s linear .3s;
    @media #{$sp} {
      width: 200px;
    }
  }

  &-box {
    height: 100%;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    // border: 0 !important;
    // background-color: transparent !important;
  }

  // tbody {
  //   height: 100%;
  // }
}

form.gsc-search-box {
  margin-bottom: 0 !important;
}

.gsc-search-button-v2,
.gsc-search-button-v2:hover,
.gsc-search-button-v2:focus {
  background-color: #fff;
}

.gsc-search-button-v2 svg {
  fill: #000 !important;
}

.gsc-search-button {
  button {
    width: 36px;
    height: 36px;
    padding: 10px;
    border: 0;
    border-radius: 50%;
    margin-left: 15px;
    outline: 0;
    font-size: 1.8rem;
    background-color: #fff;
    &:hover,:focus {
      background-color: #fff;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    // background-color: #000;
    display: block;
    // path {
    //   filter: invert(1);
    // }
  }
}

.search-button-cross {
  padding: 0 !important;
  margin-left: 10px;
  background-color: transparent !important;
  position: relative;

  &--hide {
    display: none;
  }

  i {
    font-family: "Font Awesome 5 Free";
    font-size: 2rem;
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
  }
}

.gsib_a {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

table.gsc-search-box {
  margin-bottom: 0 !important
}

.gsc-input-box {
  padding: 0 !important;
}

.gsc-input-box table tr {
  height: 36px !important;
}

.gscb_a {
  line-height: 36px !important;
}