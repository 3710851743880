@charset "UTF-8";
// ==========================================================================
// 出場選手情報
// ==========================================================================

.racerinfoTableWrap {
  border-radius: 10px;
  padding: 20px 22px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @media #{$sp} {
    padding: 20px 0;
  }
}

.racerinfoTable {
  width: 100%;
  margin-bottom: 20px;
  @media #{$sp} {
    margin-bottom: 30px;
  }

  th,td {
    border-left: 1px solid #CBCBCA;
    text-align: center;
    @media #{$sp} {
      min-width: 28px;
      padding: 5px;
      font-size: 1rem;
      white-space: nowrap;
    }

    &:first-child {
      @media #{$sp} {
        border-left: 0;
      }
    }

    &:last-child {
      border-right: 1px solid #CBCBCA;
    }
  }

  th {
    color: #fff;
    background-color: #BC0010;
  }

  td {
    // font-size: 1.2rem;
    border-bottom: 1px solid #CBCBCA;
    // border-left: 1px solid #CBCBCA;

    // &:last-child {
    //   border-right: 1px solid #CBCBCA;
    // }
  }


  &__rank {
    max-width: 46px;
  }

  &__lg {

  }

  &__name {
    font-size: 1.2rem !important;
    font-weight: 700;
    background-color: #F4F4F4;
    @media #{$sp} {
      font-size: 1rem !important;
    }
  }

  &__tag {
    border-radius: 8px;
    padding: 2px 6px;
    margin-left: 7px;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    @media #{$sp} {
      margin-left: 0;
      padding: 2px;
      display: block;
    }

    &--red {
      background-color: #BC0010;
    }

    &--blue {
      background-color: #24B6F7;
    }
  }

  &__kibetsu {

  }
}