@charset "UTF-8";
// ==========================================================================
// Page
// 固定ページのスタイル
// ※ページ数が少ないので1枚で完結。増えるようなら分割予定。
// ==========================================================================

// MT共通メニュー
.p-pageListWrap {
  margin-top: 100px;
  margin-bottom: 36px;

  &:first-child {
    @media #{$sp} {
      margin-top: 45px;
    }
  }

  &:last-child {
    @media #{$sp} {
      margin-top: 0;
    }
  }
}

.p-pageList {
  // margin-bottom: 36px;
  // @media #{$sp} {
  //   margin-bottom: 0;
  // }
  &__ttl {
    padding: 18px 0;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    margin-bottom: 24px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__item {
    font-size: 1.4rem;

    a {
      color: #302E2C;
    }
  }
}


.forgetNumber {
  height: 90px;
  border-radius: 10px;
  background-color: #707070;
  @media #{$sp} {
    padding: 15px;

    text-align: center;
  }

  &__ttl {
    font-size: 1.8rem;
    color: #fff;
  }

  &__tel {
    margin-left: 40px;
    font-size: 3.4rem;
    font-weight: 700;
    color: #fff;
    @media #{$sp} {
      margin-left: 0;
      font-size: 2.4rem;
    }
  }
}

// .howHeadline {
//   &__ttl {
//     margin-bottom: 12px;
//     font-size: 3.2rem;
//     font-weight: 700;
//     &::after {
//       content: "";
//       width: 100%;
//       height: 1.5px;
//       margin-left: 87px;
//       background-color: #fff;
//       display: inline-block;
//     }
//   }

//   &__copy {
//     margin-bottom: 20px;
//     font-size: 2.4rem;
//     font-weight: 700;
//     line-height: 1;
//   }

//   &__detail {
//     font-size: 1.6rem;
//     font-weight: 700;
//     line-height: 1.5;
//   }
// }

.sgContent {
  margin-bottom: 92.5px;
  @media #{$sp} {
    margin-bottom: 53.5px;
  }
}

.sgImage {
  border-radius: 10px;
  // margin-bottom: 34px;
  margin-bottom: 24px;
  overflow: hidden;
  @media #{$sp} {
    margin-bottom: 4px;
  }
}

.sgTtl {
  height: 36px;
  // padding: 10px 20px;
  // padding: 10px 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  // bottom: -15px;
  bottom: -21px;
  color: #fff;
  // background-color: #000;
  background-color: #BC0010;
  display: inline-block;
  position: relative;
  box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
  z-index: 2;
  @media #{$sp} {
    // height: 30px;;
    height: 28px;
    // bottom: -18px;
    bottom: -22px;
    font-size: 1.3rem;
  }

  &::before {
    content: "";
    width: 5px;
    height: 100%;
    background-color: #BC0010;
    display: inline-block;
  }
  
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 36px 16px 0 0;
    top: 0;
    border-color: #BC0010 transparent transparent transparent;
    position: absolute;
    @media #{$sp} {
      border-width: 28px 12px 0 0;
    }
  }

  &--sff {
    width: 457px;
    // width: calc(100% + 7px);
    // left: 0;
    left: 5px;
    bottom: -24px;
    display: block;
    @media #{$sp} {
      max-width: 100%;
      // width: calc(100% - 18px);
      width: calc(100% - 7px);
      bottom: -16px;
    }

    &::before {
      content: "";
      left: -5px;
      position: relative;
    }

    &::after {
      right: -16px;
      @media #{$sp} {
        border-width: 28px 12px 0 0 !important;
        right: -12px;
      }
    }

    span {
      // left: 0 !important;
      left: -5px !important;
    }
  }

  span {
    max-height: 100%;
    // padding: 10px 20px;
    padding: 7px 20px;
    left: -5px;
    bottom: 18px;
    background-color: #000;
    position: relative;
    z-index: 2;
    box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
    @media #{$sp} {
      // width: 100%;
      width: calc(100% - 5px);
      height: 100%;
      padding: 7px 15px;
      bottom: 15px;
      display: inline-block;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 16px 0 0;
      top: 0;
      right: -16px;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      @media #{$sp} {
        border-width: 28px 12px 0 0;
        right: -12px;
      }
    }
  }
}

.sgTtl--sff span {
  width: 100%;
  height: 100%;
  bottom: 45px;
  display: inline-block;
  @media #{$sp} {
    width: calc(100% - 3px);
    padding: 7px 0 7px 15px;
    left: 0;
    bottom: 35px;
  }

  &::after {
    border-width: 36px 16px 0 0;
    @media #{$sp} {
      border-width: 28px 12px 0 0;
    }
  }
}

.sgArchive {
  padding: 0 15px;
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
    max-height: 195px;
    padding: 0 3.5px;
    border: 0;
    border-radius: 0;
    background-color: #F4F4F4;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    overflow-x: hidden;
  }

  &__list {
    border-bottom: 1px solid #CBCBCA;
    padding: 10px 0;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    @media #{$sp} {
      padding: 12.5px 6.5px;
      text-align: left;
    }
  }
}

.sgArchiveBtn {
  width: 100%;
  padding: 13px 8px;
  border-bottom: 1px solid #cbcbca;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;
  display: flex;
  justify-content: space-between;
  &::after {
    content: '\f078';
    font-family: "Font Awesome 5 Free";
    border: 0;
  }
}









// archive-race.html
.archiveHeadlineWrap {
  margin-bottom: 40px;
  text-align: center;
}

.archiveHeadline {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  &__txt {
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.archiveMenu {
  padding-top: 27.5px;
  border-top: 1px solid #CBCBCA;
  border-bottom: 1px solid #CBCBCA;
  margin: 82.5px auto 54.5px;
  line-height: 1;
  &__item {
    padding: 0 15px 7px;
    font-size: 1.6rem;
    position: relative;
    @media #{$sp} {
      // padding: 0 10px;
      font-size: 1.2rem;
    }
    &--current {
      font-weight: 700;
      // color: #BA071A;
      &::after {
        content: "";
        width: 100%;
        height: 3px;
        left: 0;
        bottom: -1px;
        // background-color: #BC0010;
        background-color: #BA071A;
        position: absolute;
        display: block;
      }

      a {
        color: #BA071A !important;
      }
    }

    a {
      color: #212529;
    }
  }
}

.backNumHeadline {
  padding: 9px 22px;
  margin-top: 36px;
  margin-bottom: 22px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 27px;
  // background-color: #F4F4F4;
  @media #{$sp} {
    padding: 5px 10px;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &--border {
    border-bottom: 1px solid #CBCBCA;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &--center {
    @media #{$sp} {
      text-align: center;
    }
  }

  &--mt0 {
    margin-top: 0;
  }

  &--mb25 {
    margin-bottom: 25px;
  }
}

.infoWrap {

}

.infoItem {
  padding: 18px 20px;
  border-bottom: 1px solid #CBCBCA;
  line-height: 1;
  @media #{$sp} {
    padding: 15px 10px;
  }

  &__date {
    margin-right: 64px;
    font-size: 1.3rem;
    font-weight: 400;
    @media #{$sp} {
      // margin-right: 20px;
      margin-right: 0;
      margin-bottom: 12px;
      line-height: 1.5;
    }
  }

  &__ttl {
    font-size: 1.6rem;
    font-weight: 700;
    @media #{$sp} {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  i {
    margin-left: 10px;
    color: #CBCBCA;
  }
}




// 操作ガイド
.guideItem {
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  &:not(:first-child) {
    margin-top: 30px;
    @media #{$sp} {
      margin-top: 20px;
    }
  }
  
  &__image {
    width: 22.4%;
    max-width: 210px;
    padding: 15px 30px;
    background-color: #F4F4F4;
    text-align: center;
    @media #{$sp} {
      width: 35%;
      max-width: 120px;
      max-height: 120px;
      padding: 15px 10px;
    }

    img {
      width: auto;
      max-height: 120px;
      @media #{$sp} {
        height: 100%;
      }
    }
  }

  &__ttl {
    width: 77.6%;
    padding-left: 7.85%;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    @media #{$sp} {
      padding-top: 28px;
      padding-left: 2.3%;
      font-size: 1.3rem;
      line-height: 23px;
    }

    span {
      font-weight: 400;
      @media #{$sp} {
        margin-top: 10px;
        line-height: 1;
        display: inline-block;
      }
    }
  }

  i {
    margin-left: 4px;
    color: #CBCBCA;
  }
}



// ネット投票へのお申し込み
.guideContentWrap {
  padding: 0 70px;
  @media #{$sp} {
    padding: 0;
  }
}

.guideBtnWrap {
  max-width: 568px;
  margin: 92px auto 47px;
  @media #{$sp} {
    margin: 73px auto 47px;
  }

  &--w740 {
    max-width: 740px;
    .guideBtn {
      width: 220px;
      font-size: 1.4rem;
      text-align: center;
      @media #{$sp} {
        width: 100%;
        // width: calc(100% - 36px);
        margin: 0 auto 15px;
        display: block;
      }
    }
  }
}

.guideBtn {
  // max-width: 568px;
  height: 67px;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 40px;
  font-size: 1.8rem;
  line-height: 67px;
  @media #{$sp} {
    margin-bottom: 17px;
  }
}

.guideBtnWrap a.text-link {
  &:nth-of-type(2) {
    @media #{$sp} {
      margin-top: 12px;
    }
  }
  @media #{$sp} {
    text-align: center;
  }
}



// わかる！ネット投票 - ネット投票のお申込み・ネット投票とは
.sectionTtl {
  height: 52px;
  margin-bottom: 56px;
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #BC0010;
  bottom: -21px;
  color: #fff;
  display: inline-block;
  position: relative;
  box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
  z-index: 2;
  @media #{$sp} {
    height: 33px;
    font-size: 1.6rem;
  }

  &::before,&::after {
    content: "";
    width: 0;
    height: 0;
    top: 0;
    border-style: solid;
    border-width: 0 0 52px 16px;
    position: absolute;
    @media #{$sp} {
      border-width: 0 0 33px 16px;
    }
  }


  &::before {
    left: -16px;
    border-color: transparent  transparent #BC0010 transparent;
    // @media #{$sp} {
    //   border-width: 0 0 33px 16px;
    // }
  }
  
  &::after {
    border-color: transparent transparent transparent #BC0010;
    // @media #{$sp} {
    //   border-width: 0 0 33px 16px;
    // }
  }

  span {
    max-height: 100%;
    // padding: 10px 20px;
    padding: 10.5px 20px;
    left: -5px;
    // bottom: 18px;
    bottom: 0;
    background-color: #000;
    position: relative;
    z-index: 2;
    box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
    @media #{$sp} {
      padding: 7px 15px;
    }

    &::before,&::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      // border-width: 52px 16px 0 0;
      border-width: 47px 16px 0 0;
      top: 0;
      position: absolute;
      @media #{$sp} {
        // border-width: 33px 16px 0 0;
        border-width: 30px 16px 0 0;
      }
    }

    &::before {
      left: -16px;
      border-color: transparent #000 transparent transparent;
      // @media #{$sp} {
      //   border-width: 33px 16px 0 0;
      // }
    }

    &::after {
      right: -16px;
      border-color: #000 transparent transparent transparent;
      // @media #{$sp} {
      //   border-width: 30px 16px 0 0;
      // }
    }
  }
}


.netvoteSpImage {
  margin-bottom: 45px;
}

// .netvoteCntDetail {
//   font-size: 1.4rem;
//   font-weight: 400;
// }

.netvoteContentWrap {
  border-radius: 10px;
  padding: 64px 0 67px;
  // margin: 146px auto 100px;
  // box-shadow: 0 5px 25px rgba(0,0,0,0.20);
  text-align: center;
  // overflow: hidden;
  @media #{$sp} {
    padding: 0;
    margin: 72px auto 83px;
  }
  &--bs {
    padding: 48px 15px 45px;
    box-shadow: 0 5px 25px rgba(0,0,0,0.20);
    @media #{$sp} {
      margin: 0;
    }
  }
}

.netvoteFeatureContainer {
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0,0,0,0.20);
  overflow: hidden;
}

.netvoteFeature {
  // text-align: center;
  // background-image: url(../images/sample.png);
  // box-shadow: 0 5px 25px rgba(0,0,0,0.20);
}

.netvoteFeatureTtl {
  padding: 32px 0 34px;
  // border-radius: 10px 10px 0 0;
  line-height: 1;
  color: #fff;
  background-image: url(../images/sample.png);
  // overflow: hidden;
  @media #{$sp} {
    padding: 26px 34px 30px;
    line-height: 24px;
  }
  &__main {
    margin-bottom: 22px;
    font-size: 2.4rem;
    font-weight: 700;
    display: inline-block;
    // background-color: #24B6F7;
    position: relative;
    // box-shadow: 3px 3px 2px rgba(0,0,0,0.16);
    @media #{$sp} {
      margin-bottom: 20px;
      font-size: 2rem;
    }

    span {
      width: 85px;
      @media #{$sp} {
        width: 67px;
      }
      &:first-child {
        // width: 85px;
        line-height: 1;
        z-index: 2;
        display: block;
        position: relative;
        text-shadow: 3px 3px 2px rgba(0,0,0,.25);
      }
      &:last-child {
        // width: 100%;
        // width: 85px;
        height: 22px;
        background-color: #24B6F7;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -8px;
        z-index: 0;
        @media #{$sp} {
          height: 15px;
          bottom: -7px;
        }

        &::before,&::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 22px 10px 0 0;
          top: 0;
          border-color: #24B6F7 transparent transparent;
          position: absolute;
          @media #{$sp} {
            border-width: 15px 7px 0 0;
          }
        }

        &::before {
          transform: rotate(-180deg);
          left: -10px;
          @media #{$sp} {
            left: -7px;
          }
        }

        &::after {
          right: -10px;
          @media #{$sp} {
            right: -7px;
          }
        }
      }
    }

    // span {
    //   width: 100%;
    //   height: 22px;
    //   left: 0;
    //   background-color: #24B6F7;
    //   display: inline-block;
    //   position: absolute;
    // }
  }

  &__detail {
    font-size: 1.8rem;
    font-weight: 700;
    @media #{$sp} {
      font-size: 1.4rem;
    }
  }
}

.netvoteFeatureCnt {
  padding: 100px 60px 60px;
  @media #{$sp} {
    padding: 47px 16px 68px;
  }
  &__image {
    margin-bottom: 60px;
  }

  &__txt {
    font-size: 1.4rem;
    font-weight: 400;
  }
}

.netvoteUseful {
  margin-bottom: 62px;
  @media #{$sp} {
    margin-bottom: 30px;
  }
  &:last-child {
    @media #{$sp} {
      margin-bottom: 50px;
    }
  }
  &__image {
    margin-bottom: 23px;
    @media #{$sp} {
      width: 62%;
      margin: 0 auto 20px;
    }
  }

  &__txt {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.netvoteContactTel {
  max-width: 567px;
  width: 100%;
  height: 40px;
  margin: 28px auto 12px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  border-radius: 10px;
  background-color: #24B6F7;
  @media #{$sp} {
    // max-width: calc(100% - 30px);
    max-width: 100%;
    margin: 37px auto 22px;
    font-size: 1.6rem;
  }
  i {
    margin-right: 16px;
  }
}

.netvoteContact {
  max-width: 567px;
  width: 100%;
  padding: 25px 110px;
  border-radius: 10px;
  margin: 12px auto 0;
  background-color: #F4F4F4;
  @media #{$sp} {
    padding: 25px 20px;
  }
  // &__item {
  //   &:not(:last-child) {
  //     border-bottom: .5px solid #707070;
  //   }
  // }
  // p {
  //   padding: 0 33.5px;
  // }
}

.netvoteContactItem {
  padding: 12px 0;
  line-height: 1;
  &:not(:last-child) {
    border-bottom: 1px solid #CBCBCA;
  }
  p {
    margin: 0 33.5px;
    @media #{$sp} {
      margin: 0 15px;
    }
  }

  &__area {
    width: 64px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    @media #{$sp} {
      font-size: 1.4rem;
    }
  }

  &__tel {
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.netvote-guideTxt {
  font-size: 1.6rem;
  font-weight: 700;
}

.netvote-accountWrap {
  margin: 0 auto 165px;
  @media #{$sp} {
    margin: 0 auto 135px;
  }
}

.netvote-guideRegistWrap {
  margin: 43px auto 36px;
}

.netvote-guideRegist {
  width: 270px;
  padding: 20px 40px 30px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    width: 100%;
  }
  &:last-child {
    margin-left: 20px;
    @media #{$sp} {
      margin-top: 25px;
      margin-left: 0;
    }
  }

  &__logo {
    max-width: 210px;
    max-height: 65px;
    margin-bottom: 12px;
    img {
      object-fit: cover;
    }
  }

  &__link {
    max-width: 190px;
    padding: 5px 0;
    border-radius: 20px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #fff;
    background-color: #BC0010;
    display: block;
    @media #{$sp} {
      margin: 0 auto;
    }
  }
}

.netvote-guideAccountWrap {
  max-width: 567px;
  padding: 30px 25px;
  margin: 25px auto;
  background-color: #F4F4F4;
}

.netvote-guideAccount {
  max-width: 245px;
  margin: 30px 0;
  @media #{$sp} {
    max-width: 100%;
  }
  &:last-child {
    margin-left: 25px;
    @media #{$sp} {
      margin-left: 0;
    }
  }
  &__logo {
    max-width: 130px;
    max-height: 40px;
  }

  &__link {
    width: 105px;
    padding: 7px 0;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #24B6F7;
  }
}


// 変更届出書
.modificationImage {
  max-width: 306px;
  margin: 80px auto 88px;
  display: block;
  box-shadow: 0 3px 8px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    max-width: 100%;
    margin: 40px auto;
  }
}

.modificationBtn {
  max-width: 190px;
  // height: 50px;
  padding: 10px 0;
  margin: 0 auto;
  line-height: 1;
  @media #{$sp} {
    margin: 0 auto 40px;
  }
  span {
    margin-top: 4px;
    font-size: 1.1rem;
    font-weight: 400;
    display: block;
  }
}



// 一般銀行投票とは？
.pageBtnWrap {
  margin: 45px auto 60px;
  @media #{$sp} {
    margin: 30px auto 40px;
  }

  &>div {
    max-width: 280px;
    width: 100%;
    padding: 15px 0;
    @media #{$sp} {
      max-width: 100%;
    }

    &:first-child {
      margin-right: 38px;
      @media #{$sp} {
        margin-right: 0;
        margin-bottom: 25px;
      }
    }
  }

  a,span {
    width: 100%;
    padding: 15px 0;
  }

  p {
    margin-top: 15px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1;
    @media #{$sp} {
      font-size: 1.2rem;
    }
  }
}


.guideHeadline06 {
  font-size: 1.4rem;
  font-weight: 700;
}

// .netStadiumMenu {
//   height: 50px;
//   padding: 12.5px 0;
//   margin: 45px auto 40px;
//   border-radius: 10px;
//   display: flex;
//   @media #{$sp} {
//     width: 100%;
//     height: 45px;
//     border-radius: 0;
//     margin: 0 auto 32px;
//   }

//   &__item {
//     width: 20%;
//     font-size: 1.3rem;
//     line-height: 25px;
//     text-align: center;
//     @media #{$sp} {
//       font-size: 1.1rem;
//     }
//     &:not(:last-child) {
//       border-right: 0.5px solid #CBCBCA;
//     }
//   }
// }


.calendarTableWrap {
  @media #{$sp} {
    // margin: 0 auto;
    // padding: 0;
    // width: 100%;  /* コンテンツが見切れて見えるようにする */
    // overflow-x: auto;  /* 横スクロールの指定 */
    // white-space: nowrap;  /* 横スクロールの指定 */
    // overflow-scrolling: touch;  /* スクロールを滑らかにする */
    // -webkit-overflow-scrolling: touch;  /* スクロールを滑らかにする */
  }
}

// // カレンダー
// .calendarBtnWrap {
//   padding-bottom: 48px;
//   position: relative;
//   @media #{$sp} {
//     padding-bottom: 35px;
//   }
  
//   &::before {
//     content: "";
//     width: 100%;
//     height: 1px;
//     background-image: linear-gradient(to right, #302E2C, #302E2C 3px, transparent 3px, transparent 8px);
//     background-size: 8px .5px;
//     background-repeat: repeat-x;
//     position: absolute;
//     // top: 0;
//     bottom: 0;
//     // left: 0;
//     // right: 0;
//     @media #{$sp} {
//       // max-width: calc(100% -36px);
//       // margin: 0 auto;
//     }
//   }
// }

// .calendarBtn {
//   width: 220px;
//   height: 60px;
//   border-radius: 10px;
//   font-size: 1.4rem;
//   font-weight: 700;
//   line-height: 60px;
//   text-align: center;
//   display: block;
//   @media #{$sp} {
//     width: 100%;
//     max-width: calc(100% - 36px);
//     height: 50px;
//     margin-left: auto !important;
//     margin-right: auto !important;
//     line-height: 50px;
//   }

//   &:not(:first-child) {
//     margin-left: 40px;
//     @media #{$sp} {
//       margin-top: 15px;
//       margin-left: 0;
//     }
//   }
// }

// .caledarMonth {
//   padding: 10px 0 12px;
//   line-height: 1;
//   @media #{$sp} {
//     padding: 13px;
//   }
//   &__current {
//     font-size: 1.8rem;
//     font-weight: 700;
//     @media #{$sp} {
//       font-size: 1.4rem;
//     }
//   }

//   i {
//     font-size: 2.2rem;
//     color: #302E2C;
//     @media #{$sp} {
//       font-size: 2rem;
//     }
//   }
// }

// .calendarTable {
//   text-align: center !important;
//   thead {
//     // border-radius: 10px;
//     th {
//       height: 35px;
//       font-size: 1.1rem;
//       font-weight: 700;
//       &:first-child {
//         border-radius: 18px 0 0 18px;
//         @media #{$sp} {
//           border-radius: 0;
//         }
//       }
//       &:last-child {
//         border-radius: 0 18px 18px 0;
//         @media #{$sp} {
//           border-radius: 0;
//         }
//       }
//     }
//   }
  
//   th,td {
//     border-top: 0 !important;
//     border-bottom: 0 !important;
//     // width: 24px;
//     // min-height: 28px;
//     // border-right: .25px solid #707070;
//     &:not(:last-child) {
//       // border-right: 1px solid #CBCBCA;
//       border-right: 1px solid #dee2e6;
//     }
//   }

//   th {
//     padding-left: 0;
//     padding-right: 0;
//     // border-right: .25px solid #707070;
//     text-align: center;
//     @media #{$sp} {
//       // display: inline-block;
//     }
//   }

//   td {
//     // width: 24px;
//     width: 25px;
//     // padding: 15px 5px 10px;
//     // padding: 8px 5.5px;
//     padding: 8px 3px;
//     @media #{$sp} {
//       width: 28px;
//       // display: inline-block;
//     }
//   }
// }

// .calendarStadium {
//   // border-bottom: 1px solid #CBCBCA;
//   &__name {
//     width: 118px;
//     // width: 110px;
//     padding: 14px 12px !important;
//     @media #{$sp} {
//       // display: inline-block;
//       padding: 20px 16px !important;
//     }

//     span {
//       // width: 110px;
//       // width: 100%;
//       width: 65px;
//       // width: 116px;
//       height: 80px;
//       border-radius: 10px;
//       line-height: 80px;
//       font-size: 1.4rem;
//       font-weight: 700;
//       text-align: center;
//       background-color: #F4F4F4;
//       display: block;
//       @media #{$sp} {
//         width: 78px;
//       }
//     }
//   }


//   // &__cnt {
//   //   border-radius: 10px;
//   //   margin: 8px 5px;
//   //   display: block;
//   //   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
//   //   &--normal {
//   //     // background-color: #F8F7C5;
//   //     background-color: #efefef !important;
//   //   }
//   //   &--night {

//   //   }
//   //   &--sg {
//   //     // background-color: #95E6FF;
//   //     background-color: #FFC74F !important;
//   //   }
//   // }
// }

// .calendarJogai {
//   height: 28px;
//   border-bottom: 1px solid #CBCBCA;
//   font-size: 1.2rem;
//   font-weight: 400;
//   background-color: #F4F4F4;
//   // th {
//   //   display: inline-block;
//   // }
// }

// .calendarStadiumCnt {
//   // max-width: calc(100% - 8px);
//   cursor: pointer;
//   padding: 15px 3px 10px;
//   // margin: 8px auto;
//   border-radius: 10px;
//   font-weight: 700;
//   // margin: 8px 5px;
//   display: block;
//   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
//   &--normal {
//     // background-color: #F8F7C5;
//     background-color: #efefef !important;
//   }
//   &--night {
//     background-color: #00A9D2 !important;
//   }

//   &--sg {
//     // background-color: #95E6FF;
//     background-color: #FFC74F !important;
//   }

//   &--g2 {
//     background-color: #933ED3 !important;
//   }

//   &__grade {
//     margin-bottom: 12px;
//     font-size: 1.2rem;
//     line-height: 1;
//     display: block;
//   }

//   &__time {
//     padding: 7px 3px;
//     border-radius: 5px;
//     margin-bottom: 10px;
//     font-size: 1rem;
//     line-height: 1;
//     background-color: #fff;
//     display: block;
//   }

//   &__cs {
//     font-size: 1rem;
//     line-height: 1;
//     display: block;
//   }

//   &--midnight {
//     background-color: #386b9b;
//   }
// }

// #calendarDetailModal {
//   .modal-body-remark {
//     text-align: right;
//   }
// }

// .calendar-modalWrap {
//   max-width: 400px !important;

//   .modal-content {
//     padding: 20px !important;
//   }

//   .modal-header {
//     padding: 0 0 20px;
//   }

//   button.close {
//     top: -10px;
//     right: -10px;
//   }
// }


.netvoteFaq {
  padding: 0 70px;
  margin-bottom: 176px;
  @media #{$sp} {
    padding: 0;
  }
}

.netvoteFaqItem {
  padding: 15px 30px 25px;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  box-shadow: 0 4px 15px rgba(0,0,0,0.3);
  position: relative;
  cursor: pointer;
  @media #{$sp} {
    padding: 15px 10px 20px;
    align-items: center;
  }
  &:not(:first-child) {
    margin-top: 25px;
  }

  &__question {
    position: relative;
    &::after {
      content: "\f078";
      // height: calc(100% - 30px);
      top: 50%;
      // right: 35px;
      right: 0;
      transform: translateY(-50%);
      font-size: 2.4rem;
      font-family: "Font Awesome 5 Free";
      color: #BC0010;
      display: inline-block;
      position: absolute;
      @media #{$sp} {
        font-size: 2rem;
        right: 10px;
      }
    }

    &--open {
      &::after {
        content: "\f077";
      }
    }
  }

  &__num {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    @media #{$sp} {
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }

  &__ttl {
    max-width: calc(100% - 120px);
    padding-top: 10px;
    margin-left: 20px;
    line-height: 24px;
    // font-size: 1.4rem;
    // font-weight: 700;
    @media #{$sp} {
      max-width: calc(100% - 60px);
      margin-left: 10px;
      font-size: 1.2rem;
      line-height: 22px;
    }
  }

  &__answer {
    // height: 0;
    margin-left: 60px;
    font-weight: 400;
    // opacity: 0;
    display: none;
    transition: all .3s;
    &.is-open {
      height: auto;
      margin-top: 10px;
      opacity: 1;
      display: block;
    }
  }
}



// 会員の方へのご案内
.px-22 {
  padding: 0 22px 15px;
  border-bottom: 1px solid #CBCBCA;
  @media #{$sp} {
    padding: 0 15px 15px;
  }
  &:not(:first-child) {
    padding-top: 15px;
  }
}

.telBtn {
  width: 190px;
  height: 40px;
  border-radius: 20px;
  margin-top: 30px;
  font-size: 1.6rem;
  line-height: 40px;
  text-align: center;
  display: block;
}

.guideTable {
  width: 100%;
  td {
    padding: 18px 0;
    border-bottom: 1px solid #CBCBCA;
    &:first-child {
      border-right: 1px solid #CBCBCA;
      width: 126px;
      font-weight: 700;
      text-align: center;
      @media #{$sp} {
        width: 80px;
      }
    }
    &:last-child {
      width: calc(100% - 126px);
      padding-left: 52.5px;
      @media #{$sp} {
        width: calc(100% - 80px);
        padding-left: 15px;
      }
    }
  }
}

.guideCancellWrap {
  padding-bottom: 50px;
  border-bottom: 1px solid #CBCBCA;
  margin-top: 38px;
  // margin-bottom: 118px;
  @media #{$sp} {
    padding: 0;
    // margin-bottom: 0;
  }
}

.guideCancell {
  width: calc(50% - 25px);
  @media #{$sp} {
    width: 100%;
  }
  &__ttl {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 43px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 40px;
    color: #302E2C;
    background-color: #F4F4F4;
  }

  &__image {
    width: 65%;
    margin: 0 auto 42px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.16);
  }
}



// ネットスタジアムの閲覧環境の判定
.judgmentTable {
  width: 100%;
  margin-bottom: 35px;
  th,td {
    min-width: 47px;
    @media #{$sp} {
      min-width: 30px;
      // padding-left: 10px;
      // padding: 20px;
      padding: 20px 10px;
      font-size: 1rem;
    }
    &:first-child {
      // min-width: 184px;
      // padding-left: 22px;
      @media #{$sp} {
        // padding-left: 10px;
        padding: 20px;
        @media #{$sp} {
          padding: 20px 10px;
        }
      }
    }
    
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  th {
    padding: 15px 20px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    background-color: #F4F4F4;
    white-space: nowrap;
    // text-align: center;
    @media #{$sp} {
      padding: 20px 0;
      // font-size: 1.2rem;
      font-size: 1rem;
    }
  }

  td {
    padding: 21px 20px;
    border-bottom: 1px solid #CBCBCA;
    @media #{$sp} {
      min-width: 100%;
      // padding: 15px 20px;
      padding: 15px 10px;
    }
    i {
      font-size: 1.4rem;
      &.fa-circle {
        color: #31B7F4;
      }
      &.fa-times {
        color: #BA071A;
      }
    }
  }
}


.faqTable {
  th,td {
    &:first-child {
      border-right: 1px solid #CBCBCA;
      text-align: center;
    }
  }

  th {
    width: 62px;
    line-height: 40px;
    background-color: #858585;
    padding: 12px 23px;
    line-height: 1;
  }

  td {
    &:last-child {
      padding: 16px 23px;
    }
  }
}


.netstadiumTxt {
  border-bottom: 1px solid #CBCBCA;
  padding: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  @media #{$sp} {
    padding: 10px;
    line-height: 1.5;
  }
  &--mt {
    margin: 32px auto 65px;
    @media #{$sp} {
      margin: 15px auto 30px;
    }
  }
}

.netstadiumBtn {
  width: 190px;
  border-radius: 20px;
  margin: 0 auto 90px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #BC0010;
  display: block;
  @media #{$sp} {
    margin: 0 auto;
  }
}


.netGuideBtnWrap {
  max-width: 510px;
  margin: 64px auto 40px;
  justify-content: space-between;
  @media #{$sp} {
    margin: 0 auto 20px;
  }
}

.netGuideBtn {
  width: 220px;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  background-color: #BC0010;
  display: block;
  @media #{$sp} {
    margin: 0 auto;
    line-height: 40px;
    &:first-child {
      margin-bottom: 20px;
    }
  }
}


// オートレースモバイルのご紹介
.siteinfoSpWrap {
  max-width: 684px;
  margin: 84px auto;
  @media #{$sp} {
    // width: 188px;
    width: 200px;
    margin: 25px auto 80px;
  }

  &--w800 {
    max-width: 800px;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
    }
    .siteinfoSpImage {
      &:nth-child(n+5) {
        margin-top: 28px;
        @media #{$sp} {
          margin-top: 24px;
        }
      }
      &:nth-child(n+3) {
        @media #{$sp} {
          margin-top: 24px;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.siteinfoSpImage {
  @media #{$sp} {
    margin: 0 auto 15px;
  }

  &:not(:last-child) {
    margin-right: 20px;
    @media #{$sp} {
      margin-right: 0;
    }
  }
}

.siteinfoTable {
  width: 100%;
  th,td {
    // padding: 0 20px;
    padding: 0 10px;
    font-size: 1.4rem;
    // white-space: nowrap;
    @media #{$sp} {
      // padding: 5px;
      padding: 0;
      font-size: 1.1rem;
    }
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }
  
  tr {
    border-top: 1px solid #CBCBCA;
    &:last-child {
      border-bottom: 1px solid #CBCBCA;
    }

    &.bt-0 {
      border-top: 0;
    }
    // &:not(:first-child) {
    //   border-bottom: .5px solid #707070;
    // }
  }
  
  th {
    font-weight: 700;
    line-height: 40px;
    color: #302E2C;
    background-color: #F4F4F4;
  }

  td {
    padding: 12px 20px;
    line-height: 24px;
    border-bottom: 1px solid #CBCBCA;
    @media #{$sp} {
      padding: 5px;
    }

    .mark-circle {
      width: 14px;
      height: 14px;
      border: 2px solid #000;
      border-radius: 50%;
      margin: auto;
      display: block;
    }

    .mark-cross {
      width: 15px;
      height: 15px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &::before,&::after {
        content: "";
        width: 10px;
        height: 2px;
        background-color: #000;
        display: block;
        position: absolute;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}

.siteinfoContentWrap {
  &--w800 {
    max-width: 800px;
    margin: 0 auto;
    @media #{$sp} {
      max-width: 100%;
    }
  }
}

.siteinfo-accessContentWrap {
  max-width: 800px;
  margin: 0 auto;

  &:last-child {
    margin-top: 50px;
  }
}

.siteinfo-accessContent {
  padding: 22px 8px;
  border-bottom: 1px solid #CBCBCA;
  @media #{$sp} {
    padding: 15px 0;
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__num {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    font-size: 1.2rem;
    text-align: center;
    background-color: #CBCBCA;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__txt {
    max-width: calc(100% - 36px);
    margin-left: 15px;
    font-size: 1.4rem;
    @media #{$sp} {
      max-width: calc(100% - 36px);
      font-size: 1.2rem;
    }
  }

  &__image {
    width: 66px;
    margin-left: 27px;
    @media #{$sp} {
      margin-left: 80px;
    }
  }
}

.siteinfo-contacContent {
  max-width: 430px;
  padding: 27px 0;
  // margin: 32px auto 120px;
  margin: 32px auto 0;
  border-radius: 10px;
  font-size: 1.4rem;
  line-height: 24px;
  text-align: center;
  background-color: #F4F4F4;
  @media #{$sp} {
    margin: 40px auto 100px;
  }
}


// 一般銀行投票のしくみ
.siteinfo-largeImage {
  max-width: 800px;
  margin: 0 auto;
  @media #{$sp} {
    max-width: 100%;
  }
}

// このサイトについて
.siteinfoBtn {
  max-width: 300px;
  padding: 20px 0;
  border-radius: 10px;
  margin: 20px auto;
  font-weight: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #bc0010;
  display: block;
  @media #{$sp} {
    max-width: 100%;
    padding: 15px 0;
    font-size: 1.2rem;
  }
}

.siteinfo-image {
  max-width: 150px;
  margin: 20px 0;
  display: block;
}



// データー検索の使い方
.howItemWrap {
  padding: 36px 24px 62px;
  border-radius: 10px;
  margin: 45px auto 80px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3); 
  &__ttl {
    margin-bottom: 28px;
    font-size: 1.6rem;
  }
}

.howItem {
  max-width: 450px;
  margin: 0 auto 30px;
  font-size: 1.2rem;
  &__ttl {
    padding: 4px 25px;
    background-color: #F4F4F4;
  }

  &__txt {
    padding: 0 25px;
    line-height: 2;
  }
}


.telphoneCenter {
  padding: 25px;
  margin-top: 48px;
  border-radius: 10px;
  font-weight: 700;
  background-color: #F4F4F4;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    padding: 14px 8px;
    margin-top: 20px;
  }

  &__ttl {
    padding: 12px 15px;
    margin-bottom: 20px;
    // font-size: 2.3rem;
    font-size: 1.8rem;
    // line-height: 1;
    line-height: 1.25;
    text-align: center;
    color: #fff;
    background-color: #BC0010;
    @media #{$sp} {
      padding: 10px;
      margin-bottom: 10px;
      font-size: 1.4rem;
      line-height: 1.25;
    }
  }

  &__mainNum {
    padding-right: 18px;
    // border-right: 1px solid #CBCBCA;
    color: #302E2C;
    // font-size: 5.8rem;
    font-size: 3.2rem;
    line-height: 1;
    @media #{$sp} {
      font-size: 2.2rem;
    }
  }

  &__other {
    // margin-left: 27px;
    padding-left: 27px;
    border-left: 1px solid #CBCBCA;
    @media #{$sp} {
      // margin-left: 18px;
      padding-left: 18px;
    }
    p {
      font-size: 1.8rem;
      line-height: 1.72;
      @media #{$sp} {
        font-size: 1.2rem;
      }
    }
  }
}



.alertBoxWrap {
  padding-top: 20px;
  margin-bottom: -20px;
  // padding: 20px;
  // border-radius: 10px;
  // background-color: #fff;
  @media #{$sp} {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px){
  .alertBg-RaceInfo {
    background:#222222;
  }
}

.alertBox {
  padding: 20px 30px;
  border-radius: 10px; 
  background-color: #fff;
  &__txt {
    font-size: 1.4rem;
    line-height: 1.5;
    color: #000;
  }
}




