@charset "UTF-8";
// ==========================================================================
// Archive
// シングルスタイル
// ==========================================================================

.articleCardWrap {
  padding: 0 78px;
  @media #{$sp} {
    padding: 0 18px;
  }

  &--2 {
    padding: 0 15px;
    @media #{$sp} {
      padding: 0 18px;
    }
  }
}

.articleCard {
  // max-width: calc(50% - 12.5px);
  padding: 12px 15px 14px;
  border-bottom: 1px solid #CBCBCA;
  @media #{$sp} {
    max-width: 100%;
    padding: 13px 5px;
  }

  // &:first-child {
  //   border-top: .5px solid #707070;
  //   @media #{$sp} {
  //     border-top: 0;
  //   }
  // }

  &:first-child {
    border-top: 1px solid #CBCBCA;
    // @media #{$sp} {
    //   border-top: .5px solid #707070;
    // }
  }

  &__link {

  }

  &__image {
    width: 65px;
    height: 65px;
    margin-right: 23px;
    overflow: hidden;
    @media #{$sp} {
      width: 70px;
      height: 70px;
      margin-right: 16px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__cnt {
    // width: calc(100% - 88px);
    // @media #{$sp} {
    //   -webkit-user-drag: calc(100% - 86px);
    // }
  }

  &__date {
    font-size: 1rem;
    font-weight: 400;
    color: #302E2C;
  }

  &__stadium {
    border-radius: 10px;
    padding: 3px 12px;
    margin-left: 18px;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background-color: #BC0010;
  }

  &__ttl {
    margin-top: 12px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 20px;
    color: #302E2C;
    @media #{$sp} {
      font-size: 1.2rem;
    }
  }
}

.articleCardWrap--2 .articleCard {
  max-width: calc(50% - 12.5px);
  @media #{$sp} {
    max-width: 100%
  }
  &:nth-of-type(2) {
    border-top: 1px solid #CBCBCA;
    @media #{$sp} {
      border-top: 0;
    }
  }
  &__cnt {
    width: calc(100% - 88px);
    @media #{$sp} {
      width: calc(100% - 86px);
    }
  }
}

// .articleCardWrap--2 .articleCard__cnt {
//   width: 100%;
// }


.archiveYearTxt {
  margin-bottom: 35px;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  @media #{$sp} {
    margin-bottom: 30px;
    font-size: 1.2rem
  }
}

.archiveItemWrap {
  max-width: 780px;
  margin: 0 auto 65px;
}

.archiveItem {
  // width: 140px;
  width: 18%;
  border-radius: 10px;
  padding: 0 26px;
  border: 1px solid #CBCBCA;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  color: #302E2C;
  @media #{$sp} {
    width: 30.6%;
    padding: 0;
    line-height: 33px;
  }

  &:not(:nth-child(5n)) {
    margin-right: 2.5%;
    @media #{$sp} {
      margin-right: 0;
    }
  }

  &:not(:nth-child(3n)) {
    @media #{$sp} {
      margin-right: 4.1%;
    }
  }

  &:nth-child(n+4) {
    @media #{$sp} {
      margin-top: 14px;
    }
  }

  &:nth-child(n+6) {
    margin-top: 20px;
    @media #{$sp} {
      margin-top: 14px;
    }
  }

  &--2 {
    width: calc(36% + 20px);
    @media #{$sp} {
      width: 100%;
      margin-right: 0 !important;
    }
  }
}