@charset "UTF-8";
// ==========================================================================
// SEARCH
// SEARCHページのスタイル
// ==========================================================================

.searchWrap {
  margin-bottom: 66px;
}

.search-form {
  max-width: 658px;
  margin: 0 auto;
  
  &--fixed {
    max-width: 940px;
    width: 100%;
    padding: 25px 35px;
    border-radius: 10px;
    bottom: 30px;
    color: #fff;
    background-color: #302E2C;
    box-shadow: 0 0 4px 0px rgba(0,0,0,0.14);
    position: fixed;
    z-index: 100;

    @media only screen and (max-width: 1400px) {
      max-width: calc(100% - 36px);
      // width: 50%;
      width: 68%;
      left: 25%;
      right: 0;
      padding: 20px 18px;
      margin: auto;
      bottom: 18px;
    }

    @media only screen and (max-width: 991px) {
      // max-width: calc(100% - 94px);
      max-width: calc(100% - 36px);
      width: 100%;
      left: 0;
      right: 0;
      padding: 20px 18px;
      margin: auto;
      bottom: 18px;
    }

    @media #{$sp} {
      width: 100%;
      padding: 20px 18px;
      left: 0;
      bottom: 18px;
    }
  }

  select,input {
    height: 30px;
    border-radius: 5px;
    outline: 0;
    box-sizing: border-box;
  }

  select {
    // max-width: 140px;
    width: 100%;
    padding: 6px 8px;
    border: 0;
    font-size: 1.4rem;
    font-weight: 700;
    display: inline-block;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.14);
    @media only screen and (max-width: 1400px) {
      // max-width: calc(100% - 30px);
      // width: calc(100%);
      font-size: 1.3rem;
    }
  }

  input {
    // padding: 8px;
    // border: 0;
    // border-radius: 5px;
    // outline: 0;
  }
}

.search-formItem {
  margin-bottom: 25px;
}

.search-formInput {
  // @media only screen and (max-width: 1399px) {
  //   margin-top: 15px;
  // }
  // @media #{$sp} {
  //   width: calc(50% - 9px);
  // }

  input {
    max-width: calc(100% - 71px);
    width: 100%;
    border: 0;
    margin-left: 15px;
    @media #{$sp} {
      max-width: calc(100% - 70px);
      margin-left: 6px;
    }
  }

  p {
    font-size: 1.4rem;
    @media #{$sp} {
      font-size: 1.3rem;
    }
  }

  &__tsusan {
    input {
      max-width: 48px;
      margin: 0 8px;
      @media #{$sp} {
        max-width: 40px;
        margin: 0 6px;
      }
    }
  }

  &__racer {
    input {
      // max-width: 154px;
      margin-left: 10px;
      @media #{$sp} {
        // max-width: 93px;
        max-width: calc(100% - 46px);
        margin-left: 7px;
      }
    }
  }

  &__submit {
    // @media only screen and (max-width: 1400px) {
      // width: 100%;
      // margin-top: 15px;
      // display: block;
    // }
    input {
      // width: 140px;
      width: 100%;
      height: 40px;
      padding: 13px 0;
      margin: 0 auto;
      border: 0;
      border-radius: 20px;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      background-color: #BC0010;
      display: block;
      // outline: 0;
      @media #{$sp} {
        width: 100%;
        padding: 12px 0;
        // margin-top: 12px;
        font-size: 1.6rem;
      }
    }
  }
}

.search-formSelect {
  // max-width: 50%;
  // max-width: 200px;
  width: 100%;
  p {
    margin-right: 15px;
    @media #{$sp} {
      margin-right: 6px;
    }
  }

  select {
    width: 100%;
    // margin-left: 15px;
    @media #{$sp} {
      // margin-left: 6px;
    }
  }
}

.serch-formTtl {
  padding: 12px 27px;
  margin-bottom: 28px;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  background-color: #F4F4F4;
  @media #{$sp} {
    padding: 10px 0;
    margin-bottom: 21px;
    font-size: 1.6rem;
    text-align: center;
  }
}

.search-tabMenuWrap {
  padding-top: 15px;
  border-top: 1px solid #CBCBCA;
  border-bottom: 1px solid #CBCBCA;
  margin-bottom: 27px;
}

.search-tabMenu {
  justify-content: center;
  @media only screen and (min-width: 768px) {
    justify-content: initial;
  }
  &__item {
    flex: 1;
    padding-bottom: 8px;
    margin-bottom: -1px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;

    @media only screen and (min-width: 768px) {
      width: 100px;
      flex: none;
    }

    &--current {
      border-bottom: 3px solid #BC0010;
    }

    a {
      color: #302E2C;
    }    
  }
}

.searchTtl {
  margin-bottom: 45px;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  @media #{$sp} {
    margin-bottom: 50px;
  }
}

.search-formWrap {
  // max-width: 940px;
  // padding: 47px 18%;
  // padding: 47px 20px;
  padding: 47px 100px;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.14);
  @media #{$sp} {
    padding: 0;
    box-shadow: inherit;
  }
}

.formHeadling {
  // width: 124px;
  width: 140px;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1;
  // @media #{$sp} {
  //   width: 100%;
  //   margin-bottom: 24px;
  //   font-size: 1.4rem;
  // }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 24px;
    font-size: 1.4rem;
  }

  &__required {
    margin-left: 8px;
    font-size: 1.3rem;
    font-weight: 400;
    color: #BC0010;
  }
}

.formContent {
  max-width: calc(100% - 124px);
  @media #{$sp} {
    max-width: 100%;
  }

  input {
    @media #{$sp} {
      height: 50px;
    }
  }
}

.searchCheckWrap {
  // &:not(:nth-child(1)) {
  //   margin-top: 15px;
  // }
}

.searchCheck {
  // margin-right: 27px;
  display: flex;
  // @media #{$sp}{
  //   margin-bottom: 22px;
  // }
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 22px;
    width: 33.3333%;

    &--4col {
      width: 25%;
    }
  }

  &:not(:last-child) {
    margin-right: 20px;
    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &__input {
    height: auto !important;
  }

  &__label {
    // margin-left: 12px;
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 1;
  }
}

.searchRefund {
  display: flex;
  margin-top: 15px;
  input {
    width: 100%;
    max-width: 170px;
    padding: 5px 10px;
    border: 1px solid #CBCBCA;
    position: relative;
    &::after {
      content: "円";
      top: 0;
      bottom: 0;
      left: 10px;
      display: block;
      position: absolute;
    }
  }

  span {
    margin: 0 15px;
  }
}


.formBtnWrap {
  max-width: 384px;
  margin: 53px auto 0;
  display: flex;
  justify-content: space-between;
  @media #{$sp} {
    max-width: 100%;
    margin: 50px auto 0;
    display: block;
  }
}

.formBtn {
  max-width: 180px;
  width: 100%;
  // height: 40px;
  padding: 14px 0;
  border: 0;
  border-radius: 20px;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  @media #{$sp} {
    max-width: 100%;
    font-size: 1.6rem;
  }

  &:not(:last-child) {
    @media #{$sp} {
      margin-bottom: 20px;
    }
  }
  &--reset {
    background-color: #858585;
  }

  &--submit {
    background-color: #BC0010;
  }
}

.search-selectItemWrap {
  @media #{$sp} {
    padding: 0;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
    @media #{$sp} {
      margin-bottom: 36px;
    }
  }
}

.search-selectItem {
  width: 18.3%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.14);
  color: #302E2C;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 980px) {
    width: calc(25% - 15px);
  }

  @media #{$sp} {
    width: calc(50% - 9px);
  }

  &:nth-child(n+6) {
    margin-top: 18px;
  }

  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 18px;
    }
  }
}

.searchSelectItem {
  &__name {
    // margin-bottom: 9px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }
}

.searchSelectRank {
  margin-top: 9px;
  &__item {
    // width: 34px;
    width: 27px;
    height: 18px;
    // padding: 3px 8px;
    padding: 3px 0;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    
    &:not(:first-child) {
      margin-left: 8px;
    }

    &--sg {
      color: #fff;
      background-color: #BC0010;
    }
  
    &--g1 {
      color: #fff;
      background-color: #24B6F7;
    }
  
    &--g2 {
      background-color: #FFD731;
    }
  }
}

#conditionCheckboxesWrap {
  margin-top: 0;
  @media only screen and (min-width: 768px) {
    margin-top: 15px;
  }
}

.modal-dialog-centered {
  max-width: 90%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    max-width: 1030px;
  }
}

.modal-dialog-scrollable .modal-body {
  padding: 0;
  overflow-x: hidden;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  padding: 0 70px 50px;
  @media #{$sp} {
    // padding: 0 20px;
    padding: 0px 20px 50px;
  }
}

.modal-header {
  padding: 70px 0 24px;
  border-bottom: 1px solid #CBCBCA;
  position: relative;
}

button.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3rem;
  z-index: 99;
}

.modal-title {
  font-size: 2rem;
  margin: 0 auto;
}

.playerPickerList {
  display: flex;
  overflow-x: auto;
  margin: 38px 0 50px;
  padding-top: 15px;

  &--pt0 {
    padding-top: 0;
    margin: 0;
  }
}

.playerPicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #CCCCCC;
  width: 120px;
  height: 160px;
  flex: 0 0 120px;
  padding: 23px 12px;
  cursor: pointer;
}

.playerPickerKana {
  padding: 0 10px;
  border: 1px solid #cbcbca;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: #cbcbca;
  border-radius: 5px;
  cursor: pointer;
}

.playerPicker__label {
  font-size: 12px;
  color: #858585;
  margin-top: 8px;
}

.playerPicker .playerPicker__icon {
  color:#858585;
  font-size: 37px;
}

.playerListModal__syllabaryLine {
  // padding: 10px 27px;
  width: 100%;
  padding: 14px 27px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  background-color: #302E2C;
  margin-top: 25px;
}

.playerListModal__row >* {
  flex: 0 0 50%;
  max-width: 50%;

}

@media only screen and (min-width: 576px) {
  .playerListModal__row >* {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.playerListModal__playerItem {
  width: 25%;
  text-align: center;
  padding: 7px;
  border: 1px solid #CBCBCA;
  // margin-bottom: 18px;
  // box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.14);
  // border-radius: 10px;
  cursor: pointer;
}

.playerPickerList > :not(:last-child) {
  margin-right: 15px;
}

.selectedPlayer {
  border-radius: 10px;
  width: 120px;
  height: 160px;
  flex: 0 0 120px;
  position: relative;
}

.selectedPlayer__remove {
  display: flex;
  position: absolute;
  top: -13px;
  right: -5px;
  font-size: 18px;
  border-radius: 50%;
  background: #fff;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: #CBCBCA;
}

.selectedPlayer:hover .selectedPlayer__remove {
  display: flex;
}

@media only screen and (min-width: 576px) {
  .selectedPlayer__remove {
    display: none;
  }
}

.selectedPlayer__avatar {
  width: 120px;
  height: 120px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.selectedPlayer__info {
  background-color: #302E2C;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selectedPlayer__info__name {
  font-size: 11px;
  font-weight: bold;
  color: #fff;
}

.selectedPlayer__info__handicap {
  font-size: 11px;
  color: #fff;
}

.datepicker-days {
  background-color: #fff;
}

.datepicker {
  padding: 0;
  @media #{$sp} {
    height: 50px;
  }

  &:first-child {
    @media #{$sp} {
      margin-bottom: 16px;
    }
  }

  input {
    font-size: 1.3rem;
    @media #{$sp} {
      height: 100%;
    }
    &::placeholder {
      text-align: center;
      @media #{$sp} {
        text-align: left;
      }
    }
  }

  .input-group-append {
    @media #{$sp} {
      width: 55px;
    }

    .input-group-text {
      @media #{$sp} {
        width: 100%;
        text-align: center;
      }

      i {
        @media #{$sp} {
          margin: auto;
          font-size: 2.4rem;
        }
      }
    }
  }
}

.datepicker .input-group-text {
  background-color: #CBCBCA;
}

.datepicker .datepicker__icon {
  color:#fff;
  font-size: 18px;
}

.kyososhaWrap {
  
}

.carList {
  padding: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  &__item {
    // max-width: 150px;
    // width: 20%;
    width: 16.6%;
    // border: 1px solid #CBCBCA;
    // margin-bottom: 15px; 
    font-size: 1.2rem;
    text-align: center;
    @media #{$sp} {
      width: 33.3%;
    }

    a {
      // padding: 10px 15px;
      padding: 10px 0;
      color: #302E2C;
      display: block;
      @media #{$sp} {

      }
    }
  }
}

.searchTags {
  margin-bottom: 64px;
  overflow-y: hidden;
  @media #{$sp} {
    margin-bottom: 42px;
  }
  .tags__tag {
    .tags__tag__a {
      padding: 14px 30px;
    }
  }
}



.kyosoMenu {
  padding-bottom: 10px;
  border-bottom: 1px solid #CBCBCA !important;
  margin-bottom: 20px;
}

.kyosoEnMenu {
  padding-bottom: 10px;
  border-bottom: 1px solid #CBCBCA !important;
  a {
    padding: 5px 8px !important;
  }
}