@charset "UTF-8";
// ==========================================================================
// 出走表
// ==========================================================================

.today-programWrap {
  padding: 40px 20px 100px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}

.programTab {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #CBCBCA;
  border-bottom: 0;
  margin-bottom: 20px;
  @media #{$sp} {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &__item {
    @media #{$sp} {
      display: inline-block;
    }
    &:not(:last-child) {
      margin-right: 20px;
    }

    a {
      border: 1px solid #DDDDDD;
      border-radius: 5px !important;
      font-size: 1.2rem;
      font-weight: 700;
      color: #302E2C;
      background-color: #F4F4F4;
      @media #{$sp} {
        white-space: nowrap;
      }

      &.active {
        border-color: #bc0010 !important;
        color: #fff !important;
        background-color: #BC0010 !important;
      }
    }
  }
}

.race-infoWrap {
  padding: 20px 20px 25px;
  border-radius: 10px;
  margin-bottom: 27px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    margin-bottom: 45px;
  }
}

.race-infoTable {
  // margin-top: 10px;
  // @media #{$sp} {
  //   margin-top: 20px;
  // }
  &:first-child {
    width: 58%;
    @media #{$sp} {
      width: 100%;
      margin-top: 20px;
    }
  }

  &:last-child {
    width: 42%;
    @media #{$sp} {
      width: 100%;
    }
  }

  th,td {
    text-align: center;
  }

  th {
    color: #fff;
    background-color: #BC0010;
    border-right: 1px solid #CBCBCA;
    @media #{$sp} {
      width: 25%;
    }
  }

  td {
    border-right: 1px solid #CBCBCA;
    border-bottom: 1px solid #CBCBCA;
    @media #{$sp} {
      width: 25%;
    }

    &:first-child {
      border-left: 1px solid #CBCBCA;
    }
  }
}

.raceNum {
  &--01 {
    color: #302E2C;
    background-color: #fff;
  }

  &--02 {
    color: #fff;
    background-color: #302E2C;
  }

  &--03 {
    color: #fff;
    background-color: #EB5353;
  }

  &--04 {
    color: #fff;
    background-color: #3A8AEC;
  }

  &--05 {
    color: #302E2C;
    background-color: #F6E92A;
  }

  &--06 {
    color: #fff;
    background-color: #5C9E00;
  }

  &--07 {
    color: #fff;
    background-color: #FF9900;
  }

  &--08 {
    color: #fff;
    background-color: #EF6EA6;
  }
}

.programTable {
  width: 100%;

  tr {
    // border-bottom: 1px solid #CBCBCA;
    border-bottom: 1px solid #CBCBCA;
  }

  th,td {
    min-width: 48px;
    max-width: 185px;
    border-right: 1px solid #CBCBCA;
    @media #{$sp} {
      min-width: 24px;
      white-space: nowrap;
    }
    &:first-child {
      border-left: 1px solid #CBCBCA;
      @media #{$sp} {
        font-size: 1.2rem;
      }
    }

    &:not(:first-child) {
      min-width: 66px;
    }
  }

  th {
    height: 35px;
  }

  td {
    padding: 0;
    // padding: 6px 0;
    max-height: 88px;
    text-align: center;

    &:nth-child(2) {
      padding: 4px 12px;
      @media #{$sp} {
        padding: 0 4px;
      }
    }

    p {
      height: 25%;
      font-size: 1rem;
      // line-height: 22px;
      &:nth-child(even) {
        background-color: #F4F4F4;
      }
    }
  }

  &__head {
    // max-width: 185px;
    font-size: 1.2rem;
    text-align: center;
    background-color: #302E2C;
    span {
      font-size: 1rem;
      display: block;
    }

    &--racer {
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  // &__num {
  //   &--01 {
  //     color: #302E2C;
  //     background-color: #fff;
  //   }

  //   &--02 {
  //     color: #fff;
  //     background-color: #302E2C;
  //   }

  //   &--03 {
  //     color: #fff;
  //     background-color: #EB5353;
  //   }

  //   &--04 {
  //     color: #fff;
  //     background-color: #3A8AEC;
  //   }

  //   &--05 {
  //     color: #302E2C;
  //     background-color: #F6E92A;
  //   }

  //   &--06 {
  //     color: #fff;
  //     background-color: #5C9E00;
  //   }

  //   &--07 {
  //     color: #fff;
  //     background-color: #FF9900;
  //   }

  //   &--08 {
  //     color: #fff;
  //     background-color: #EF6EA6;
  //   }
  // }
}

.programTableInfo {
  // width: 185px;
  padding: 14px 20px 14px 12px;
  line-height: 1 !important;
  text-align: left !important;
  &__racer {
    max-width: 60px;
    max-height: 60px;
    border-radius: 5px;
    margin-right: 12px;
    overflow: hidden;
    @media #{$sp} {
      max-width: 50px;
      max-height: inherit;
      display: flex;
      align-items: center;
    }
  }

  &__cnt {
    padding: 5px 0;
  }

  &__name {
    margin-bottom: 7px;
    font-size: 1.4rem;
    font-weight: 700;
  }

  &__affiliation {
    margin-bottom: 7px;
    font-size: 1.2rem !important;
    background-color: #fff !important;
  }

  &__data {
    font-size: 1.2rem !important;
  }
}