.archiveWrap {
  padding: 0;
}

.monthMenu {
  max-width: 100%;
  padding-bottom: 23px;
  border-bottom: 1px solid #CBCBCA !important;
  margin-bottom: 42px;
  @media #{$sp} {
    max-width: calc(100% + 36px);
    width: calc(100% + 36px);
    padding-bottom: 13px;
    margin-left: -18px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    // display: inline-block;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }

    @media #{$sp4} {
      max-width: calc(100% + 30px);
      margin-left: -15px;
    }
  }

  .nav-item {
    @media #{$sp} {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.tab-content {
  @media #{$sp} {
    padding-bottom: 80px;
  }
}

.articleItem {
  // max-width: 18.3%;
  max-width: 23.4%;
  padding: 0 !important;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0 rgba(0,0,0,0.16);
  @media #{$sp} {
    max-width: calc(50% - 10px);
  }

  @media #{$sp4} {
    max-width: calc(50% - 7px);
  }
  
  &:not(:nth-child(4n+1)) {
    // margin-left: 2.125%;
    @media #{$sp} {
      // margin-left: auto;
      // margin-left: 0;
    }
  }

  &:nth-child(n+5) {
    margin-top: 32px;
    @media #{$sp} {
      margin-top: 0;
    }
  }

  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 25px;
    }
  }

  &__image {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }

  &__cnt {
    border-radius: 0 0 10px 10px;
    padding: 17px 12px 23px 16px;
    color: #302E2C;
    background-color: #fff;

    @media #{$sp} {
      padding: 15px 10px;
    }
  }

  &__date {
    font-size: 1rem;
    line-height: 1;
  }

  &__place {
    padding: 3px 12px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background-color: #BC0010;
  }

  &__txt {
    margin-top: 12px;
    font-size: 1rem;
    @media #{$sp} {
      font-size: 1.1rem;
    }
  }
}