.breadCrumb {
  padding: 15px 0 0;
  // margin: 0 0 15px;
  overflow-x: auto;

  &--netstadium {
    padding: 0;
    margin: -40px 0 30px;
    @media #{$sp} {
      margin: -20px 0 30px;
    }
  }

  &__item {
    font-size: 1.2rem;
    color: #302E2C;
    white-space: nowrap;
    &:not(:last-child) {
      // margin-right: 10px;
      position: relative;

      &::after {
        content: ">";
        margin: 0 10px;
        display: inline-block;
      }
    }

    a {
      color: #302E2C;
    }
  }
  
}