@charset "UTF-8";
// ==========================================================================
// Base
// ここには基本設定部分のみを記載
// ==========================================================================

html {
	font-size: 62.5%;
	height: 100%;
	@media only screen and (max-width:320px) {
		font-size: 53.3%;
	}
}

body {
	width: 100%;
	min-height: 100%;
	// height: 100%;
	// font-family: $font;
	// font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
	font-family: "Hiragino Kaku Gothic ProN";
	// font-display: swap;
	// color: #fff;
	background-color: #F4F4F4;
	box-sizing: border-box;
	word-wrap: break-word;
	-webkit-text-size-adjust: 100%;
	-webkit-print-color-adjust: exact;
	position: relative;
	@media #{$sp} {
		@include spBaseFont;
	}
	@media #{$tbpc} {
		@include pcBaseFont;
	}
	@media all and (-ms-high-contrast:none) {
		// font-family: $fontIe;
	}
}


// 画像
img{
	vertical-align: middle;
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
	width: 100%;
	max-height: inherit;
	height: auto;
}


// リンク色
a {
	// color: $colorDefalut;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: none;
		// opacity: .7;
	}
}

/* input */
input{
	// outline: none;
	// -webkit-appearance: none;
	&[type=text] {
		// font-size: 16px;
		// transform: scale(0.8);
	}
}

// ラッパー
.wrap {
	position: relative;
	@media #{$tbpc} {
		width: 100%;
		min-height: 100%;
		margin: 0 auto;
	}
	@media #{$sp} {
		// padding-top: 60px;
	}
}

/* Text */
.text-black {
  color: #302E2C !important;
}

.text-grey {
  color: #595856 !important;
}

.text-lightGrey {
  color: #e0e0e0 !important;
}

.text-red {
  color: #BC0010 !important;
}

.text-blue {
  color: #24B6F7 !important;
}

.text-yellow {
  color: #F7C624 !important;
}

/* Color */
.bgcolor-black {
  background-color: #302E2C !important;
}

.bgcolor-grey {
  background-color: #595856 !important;
}

.bgcolor-lighterGrey {
  background-color: #ddd !important;
}

.bgcolor-lightGrey {
  background-color: #F4F4F4 !important;
}

.bgcolor-red {
  background-color: #BC0010 !important;
}

.bgcolor-blue {
  background-color: #24B6F7 !important;
}

.bgcolor-yellow {
  background-color: #F7C624 !important;
}

.bgcolor-white {
  background-color: #fff !important;
}

.bgcolor-green {
  background-color: #5c9e00;
}

.bgcolor-kawaguchi {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #24F7B6 !important;
    opacity: 0.1;
    display: block;
    position: absolute;
  }
  // opacity: 0.1;
}

.bgcolor-isesaki {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #24b6f7 !important;
    opacity: 0.1;
    display: block;
    position: absolute;
  }
}

.bgcolor-hamamatsu {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #6349D0 !important;
    opacity: 0.1;
    display: block;
    position: absolute;
  }
}

.bgcolor-iizuka {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F7EF24 !important;
    opacity: 0.1;
    display: block;
    position: absolute;
  }
}

.bgcolor-sanyou {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #E63A3A !important;
    opacity: 0.1;
    display: block;
    position: absolute;
  }
}

/* Button */
.btn {
  /* border-radius: 30px; */
	line-height: 1;
  display: inline-block;
  @media #{$sp} {
    margin:0 auto;
  }
  
  i {
    margin-left: 25px;
  }
}

.btn-lg {
  max-width: 190px;
  min-width: 122px;
  padding: 15px;
  margin-top: 25px;
  font-size: 16px;
  display: block;
}

.btn-md {
  min-width: 88px;
  font-size: 14px;
}

.btn-sm {
  min-width: 50px;
  font-size: 10px;
}

.btn-radius {
  border-radius: 30px;
}

.btn-white {
  border: 1px solid #BC0010;
  color: #BC0010;
  background-color: #fff;
}

.btn-red {
  color: #fff;
  background-color: #BC0010;
}

.btn-back {
  color: #302E2C;
  background-color: #DDDDDD;
}

.btn-black {
  color: #fff;
  background-color: #302E2C;
}

.btn-blue {
  color: #fff;
  background-color: #24B6F7;
}

a.btn.disabled {
  opacity: .5;
}

.btn-border--red {
  border: 1px solid #BC0010;
  color: #BC0010;
  background-color: #fff;
}

.btn-border--black {
  border: 1px solid #141313;
  color: #302E2C;
  background-color: #fff;
}

.btn-border--grey {
  border: 1px solid #CBCBCA;
  color: #707070;
  background-color: #fff;
}

.btn-border--blue {
  border: 1px solid #24B6F7;
  color: #24B6F7;
  background-color: #fff;
}

.cautionWrap {
  max-width: 100%;;
  padding-bottom: 27px;
  border-bottom: 1px solid #CBCBCA;
  margin: 0 auto 32px;
  // box-sizing: border-box;
}

.caution {
  max-width: 100%;
	padding: 28px;
  border-radius: 10px;
  margin-top: 67px;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 25px;
	color: #fff;
  background-color: #BC0010;
  box-sizing: border-box;
  @media #{$sp} {
    padding: 17px 16px;
    // margin: 25px 18px 0;
    margin: 25px 0 0;
    font-size: 1.1rem;
    line-height: 22px;
  }
}


// Tab
.nav-tabs .nav-item {
  margin-bottom: 0;
}
.nav-tabs.nav-bbRadius {
  border-bottom: 4px solid #BC0010;
  .nav-item {
    border-radius: 5px 5px 0 0;
    margin-bottom: -2px !important;
    // background-color: #BC0010;
    &:first-child {
      margin-left: 18px;
    }
  }
  
  .nav-link {
    padding: 12px 17px;
    color: #302E2C;
    &.active {
      border: 1px solid #BC0010;
      border-radius: 5px 5px 0 0;
      background-color: #BC0010;
      color: #fff;
    }
  
    &:focus,&:hover {
      border: 1px solid transparent;
    }
  }
}


// 角丸背景色
.nav-tabs.nav-radius {
  border-bottom: 0;
  .nav-item {
    border-radius: 0;
    // border-radius: 30px;
    &:first-child {
      .nav-link {
        border-radius: 30px 0 0 30px;
      }
    }
    &:last-child {
      .nav-link {
        border-radius: 0 30px 30px 0;
      }
    }

    &:not(:first-child) {
      border-left: 1px solid #ACABAB;
    }
  }

  .nav-link {
    // padding: 10px 50px;
    width: 114px;
    border: 0;
    border-radius: 0;
    text-align: center;
    color: #CBCBCA;
    background-color: #595856;
    &.active {
      color: #fff;
      background-color: #BC0010;
    }
  }
}


// テキスト
.nav-tabs.nav-text {
  border-bottom: 0;
  .nav-item {
    border-radius: 0;
    // border-radius: 30px;
    // &:first-child {
    //   .nav-link {
    //     border-radius: 30px 0 0 30px;
    //   }
    // }
    // &:last-child {
    //   .nav-link {
    //     border-radius: 0 30px 30px 0;
    //   }
    // }

    &:not(:first-child) {
      // border-left: 1px solid #ACABAB;
      margin-left: 40px;
    }
  }

  .nav-link {
    padding: 5px 0;
    // width: 114px;
    border: 0;
    border-radius: 0;
    text-align: center;
    color: #CBCBCA;
    // background-color: #595856;
    &.active {
      // color: #fff;
      font-weight: 700;
      color: #BC0010;
      // background-color: #BC0010;
      border-bottom: 2px solid #BC0010;
    }
  }
}


// 下線
.nav-tabs.nav-bb {
  // border-bottom: 0;
  border: 1px solid #E0E0E0;
  .nav-item {
    border-radius: 0;
    // border-radius: 30px;
    &:first-child {
      .nav-link {
        // border-radius: 30px 0 0 30px;
      }
    }
    &:last-child {
      .nav-link {
        // border-radius: 0 30px 30px 0;
      }
    }

    &:not(:first-child) {
      // border-left: 1px solid #ACABAB;
    }
  }

  .nav-link {
    // padding: 10px 50px;
    width: 114px;
    border: 0;
    border-radius: 0;
    text-align: center;
    color: #CBCBCA;
    background-color: #fff;
    &:hover {
      color: #BC0010;
    }
    &.active {
      border-bottom: 3px solid #BC0010;
      font-weight: 700;
      color: #BC0010;
      // background-color: #BC0010;
    }
  }
}


// 背景色
.nav-tabs.nav-bg {
  // border-bottom: 0;
  border: 1px solid #E0E0E0;
  .nav-item {
    // border-left: 1px solid #ACABAB;
    border-radius: 0;
    // box-sizing: border-box;
    // border-radius: 30px;
    &:first-child {
      .nav-link {
        // border-radius: 30px 0 0 30px;
      }
    }
    &:last-child {
      .nav-link {
        // border-radius: 0 30px 30px 0;
      }
    }

    &:not(:first-child) {
      // border-left: 1px solid #ACABAB;
    }
  }

  .nav-link {
    // padding: 10px 50px;
    width: 114px;
    border: 0;
    border-radius: 0;
    text-align: center;
    color: #CBCBCA;
    background-color: #fff;
    &:hover {
      color: #BC0010;
    }
    &.active {
      font-weight: 700;
      color: #fff;
      background-color: #BC0010;
      // background-color: #BC0010;
    }
  }
}


// 角丸
.nav-tabs.nav-radius15 {
	// border-bottom: 4px solid #BC0010;
	border-bottom: 0;
  .nav-item {
    // border-radius: 15px;
    // margin-bottom: -2px !important;
    // background-color: #BC0010;
    &:first-child {
      margin-left: 18px;
    }
  }
  
  .nav-link {
		padding: 5px 15px;
		font-size: 1.4rem;
		// font-weight: 700;
		line-height: 1;
    color: #302E2C;
    &.active {
      border: 1px solid #BC0010;
			border-radius: 15px;
			font-weight: 700;
      background-color: #BC0010;
      color: #fff;
    }
  
    &:focus,&:hover {
      border: 1px solid transparent;
    }
  }
}





// Link
.text-link {
  font-size: 1.4rem;
  font-weight: 400;
  @media #{$sp} {
    font-size: 1.2rem;
  }
  // 灰色
  &--gray {
    color: #302E2C;
  }

  // 下線
  &--underline {
    text-decoration: underline;
  }
}

.text-vertical {
  p {
    margin: auto;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.textBox {
  padding: 22px 38px;
  margin-top: 14px;
  background-color: #F4F4F4;
  @media #{$sp} {
    padding: 15px;
  }

  &--white {
    background-color: #fff;
  }
}


.px-70 {
  padding: 0 70px;
  @media #{$sp} {
    padding: 0;
  }
}

.tableHead {
  font-size: 1.4rem;
  @media #{$sp} {
    // font-size: 1rem;
    font-size: 1.1rem;
  }

  &--square {
    width: 60px;
    height: 60px;
  }
}

.tableContent {
  padding-left: 30px !important;
  font-size: 1.4rem;
  line-height: 1.5;
  @media #{$sp} {
    padding-left: 15px !important;
    font-size: 1rem;
    font-weight: 400 !important;
  }

  span {
    font-size: 1.2rem;
    @media #{$sp} {
      font-size: 1rem;
    }
  }
}