@charset "UTF-8";
// ==========================================================================
// Utility
// モジュールの設定
// ==========================================================================


// hidden
// ----------------------------------------------------------------- //

.u-hidden {
	display: none !important;
}

.u-hidden--sp {
	@media #{$sp} {
		display: none !important;
	}
}

.u-hidden--pc {
	@media #{$tbpc} {
		display: none !important;
	}
}



// align
// ----------------------------------------------------------------- //

.u-left {
	text-align: left;
}
.u-left--pc {
	@media #{$tbpc} {
		text-align: left;
	}
}
.u-left--sp {
	@media #{$sp} {
		text-align: left;
	}
}

.u-center {
	text-align: center;
}
.u-center--pc {
	@media #{$tbpc} {
		text-align: center;
	}
}
.u-center--sp {
	@media #{$sp} {
		text-align: center;
	}
}

.u-right {
	text-align: right;
}
.u-right--pc {
	@media #{$tbpc} {
		text-align: right;
	}
}
.u-right--sp {
	@media #{$sp} {
		text-align: right;
	}
}



// space
// ----------------------------------------------------------------- //

$marginStride: 100;
@while $marginStride > -1 {
	.u-mt#{$marginStride} {
		margin-top: #{$marginStride}px !important;
	}
	.u-mb#{$marginStride} {
		margin-bottom: #{$marginStride}px !important;
	}
	.u-mr#{$marginStride} {
		margin-right: #{$marginStride}px !important;
	}
	.u-ml#{$marginStride} {
		margin-left: #{$marginStride}px !important;
	}
	.u-m#{$marginStride} {
		margin: #{$marginStride}px !important;
	}
	@media #{$tbpc} {
		.u-mt#{$marginStride}--pc {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--pc {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--pc {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--pc {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--pc {
			margin: #{$marginStride}px !important;
		}
	}	
	@media #{$sp} {
		.u-mt#{$marginStride}--sp {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--sp {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--sp {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--sp {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--sp {
			margin: #{$marginStride}px !important;
		}
	}
	$marginStride: $marginStride - 1;
}


$paddingStride: 100;
@while $paddingStride > -1 {
	.u-pt#{$paddingStride} {
		padding-top: #{$paddingStride}px !important;
	}
	.u-pb#{$paddingStride} {
		padding-bottom: #{$paddingStride}px !important;
	}
	.u-pr#{$paddingStride} {
		padding-right: #{$paddingStride}px !important;
	}
	.u-pl#{$paddingStride} {
		padding-left: #{$paddingStride}px !important;
	}
	.u-p#{$paddingStride} {
		padding: #{$paddingStride}px !important;
	}
	@media #{$tbpc} {
		.u-pt#{$paddingStride}--pc {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--pc {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--pc {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--pc {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--pc {
			padding: #{$paddingStride}px !important;
		}
	}
	@media #{$sp} {
		.u-pt#{$paddingStride}--sp {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--sp {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--sp {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--sp {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--sp {
			padding: #{$paddingStride}px !important;
		}
	}
	$paddingStride: $paddingStride - 1;
}



// other
// ----------------------------------------------------------------- //

.u-clearfix:after {
	content:" ";
	display:block;
	clear:both;
}


