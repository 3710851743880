@charset "UTF-8";
// ==========================================================================
// レース結果・リプレイ
// ==========================================================================

.recentTableWrap {
  border-radius: 10px;
  padding: 20px 20px 24px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @media #{$sp} {
    // padding-right: 0;
    // margin-right: -18px;
  }

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.recentLink {
  padding: 5px 7px;
  border: 1px solid #CBCBCA;
  font-size: 1rem;
  line-height: 1;
  color: #302E2C;
  &:hover {
    color: #fff;
    background-color: #302E2C;
    transition: all .3s;
  }
}

.recentTable {
  width: 100%;

  tr {
    border-bottom: 1px solid #CBCBCA;

    &:not(:first-child) {
      border-top: 1px solid #CBCBCA;
    }

    // &:last-child {
    //   border-bottom: 1px solid #CBCBCA;
    // }
  }

  th,td {
    min-width: 60px;
    max-width: 100%;
    border-right: 1px solid #CBCBCA;
    text-align: center;

    &:first-child {
      border-left: 1px solid #CBCBCA;
    }
  }

  th {
    color: #fff;
    background-color: #BC0010;
  }

  td {
    i {
      margin-left: 3px;
    }
  }

  // a {
  //   background: url(../images/recent_link_red.svg) 2px 2px no-repeat; 
  //   display: block;
  //   // &:hover {
  //   //  background: url(../images/recent_link_white.svg) 2px 2px no-repeat; 
  //   // }
  // }
}

.link-bg {
  color: #302E2C;
  display: block;
  position: relative;
  transition: all .3s;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    top: 0;
    right: 2px;
    display: block;
    position: absolute;
  }

  &:hover {
    color: #fff;
    &::before {
      background: url(../images/link-bg_white.svg) 2px 2px no-repeat; 
    }
  }

  &--red {
    // background: url(../images/link-bg_red.svg) 2px 2px no-repeat; 
    &::before {
      background: url(../images/link-bg_red.svg) 2px 2px no-repeat; 
    }

    &:hover {
      background-color: #BC0010;
    }
  }

  &--blue {
    // background: url(../images/link-bg_blue.svg) 2px 2px no-repeat; 
    &::before {
      content: "";
      background: url(../images/link-bg_blue.svg) 2px 2px no-repeat;
    }
    &:hover {
      background-color: #24B6F7;
    }
  }
}


.recentBtnWrap {
  margin-top: 75px;
  @media #{$sp} {
    margin-top: 85px;
  }
  
  .c-btn {
    padding: 18px 0;
    max-width: 236px;
    font-size: 1.2rem;
    @media #{$sp} {
      max-width: 100%;
    }
  }
}


.resultBtnWrap {
  .c-btn {
    max-width: 246px;
    padding: 20px;
  }
}