@charset "UTF-8";
// ==========================================================================
// CONTACT
// CONTACTページのスタイル
// ==========================================================================

.p-contactHeadline {
  margin-top: 75px;
  margin-bottom: 75px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  @media #{$sp} {
    // margin-top: 20px;
    margin-top: 40px;
    margin-bottom: 45px;
    font-size: 2rem;
  }
}

.inquiry-mainHeadline {
  margin-bottom: 60px;
  font-size: 2rem ;
  font-weight: 700;
  text-align: center;
}

.inquiry-headline {
  margin-bottom: 40px;
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  @media #{$sp} {
    margin-bottom: 28px;
    text-align: center;
  }
}

.contact-form {
  max-width: 840px;
  margin: 0 auto;
  // margin: 76px auto 81px;
  // margin: 0 auto 80px;
  @media #{$sp} {
    // margin: 0 auto 50px;
  }

  .headline {
    margin-bottom: 74px;
  }
}

.contactBox {
  // padding: 55px 40px 47px;
  padding: 55px 40px;
  border-radius: 10px;
  background-color: #F4F4F4;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    padding: 35px 20px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
    @media #{$sp} {
      margin-bottom: 25px;
    }
  }

  &:last-child {
    @media #{$sp} {
      margin-bottom: 90px;
    }
  }

  &__ttl {
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: 700;
  }

  a.btn {
    margin-top: 18px;
    @media #{$sp} {
      margin-top: 40px;
    }
  }
}

form .row {
  padding: 0 15px;
}

.contactItem {
  padding: 0;
  margin-bottom: 28px;
  @media #{$sp} {
    margin-bottom: 25px;
  }

  // &:nth-child(2n) {
  //   margin-right: 15px;
  // }

  // &:nth-child(2n+1) {
  //   margin-left: 15px;
  // }

  &--required {
    input {
      border: 1px solid #BC0010 !important;
    }
  }

  &__label {
    margin-bottom: 16px;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    @media #{$sp} {
      margin-bottom: 12px;
      font-size: 1.4rem;
    }

    &--required {
      &::after {
        content: "必須";
        margin-left: 18px;
        font-size: 1.4rem;
        font-weight: 700;
        color: #BC0010;
        display: inline-block;
        @media #{$sp} {
          margin-left: 8px;
          font-size: 1.3rem;
          font-weight: 400;
        }
      }
    }
  }

  input,textarea,select {
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    outline: none;
    display: block;
    box-sizing: border-box;
    &::placeholder {
      color: #CBCBCA;
    }
  }

  input {
    height: 50px;
    padding: 17px 16px;
    font-size: 1.6rem;
    line-height: 1;
  }

  textarea {
    max-width: 100%;
    width: 100%;
    padding: 16px;
  }

  select {
    height: 50px;
    // padding: 17px 16px;
    padding: 16px 16px;
  }

  &__message {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 700;
    color: #BC0010;
  }
}

.contactItem.col-md-6 {
  max-width: calc(50% - 20px);
  @media #{$sp} {
    max-width: 100%;
  }
}

.privacyTxt {
  margin-bottom: 36px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #302E2C;
  @media #{$sp} {
    margin-bottom: 25px;
    font-size: 1.3rem;
  }
  &__link {
    color: #302E2C;
    text-decoration: underline;
  }
}

.btn-send {
  width: 420px;
  height: 45px;
  border: 0;
  border-radius: 23px;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 45px;
  color: #fff;
  background-color: #BC0010;
  display: block;
  @media #{$sp} {
    width: 100%;
  }
}

.inquiry-btnWrap {
  @media #{$sp} {
    margin-top: 50px;
  }
  .btn-send {
    max-width: 300px;
    margin: 0;
    @media #{$sp} {
      max-width: 100%;
    }

    &:last-child {
      margin-left: 20px;
      @media #{$sp} {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}

.contactTelWrap {
  // margin: 25px auto;
  max-width: 626px;
  margin-top: 25px;
}

.contactTel {
  font-size: 1.3rem;
  @media #{$sp} {
    
  }
  &:not(:last-child) {
    // margin-right: 40px;
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  span {
    @media #{$sp} {
      width: 90px;
      display: inline-block;
    }
  }

  a {
    border: 1px solid #CBCBCA;
    border-radius: 5px;
    padding: 8px 15px;
    margin-left: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    display: inline-block;
    @media #{$sp} {
      max-width: calc(100% - 100px);
      width: 100%;
      margin-left: 0;
    }
  }
}

.contactTxt {
  margin-bottom: 22px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #302E2C;
  @media #{$sp} {
    font-size: 1.2rem;
  }
  &--bold {
    margin-bottom: 22px;
    font-size: 1.6rem;
    @media #{$sp} {
      margin-bottom: 18px;
      font-size: 1.4rem;
    }
  }
}


.thanks-message {
  margin-top: -20px;
  margin-bottom: 50px;
  font-size: 1.6rem;
  text-align: center;
  @media #{$sp} {
    font-size: 1.4rem;
  }
}