
.tags {
  display: flex;
  // overflow: scroll;
  // overflow-x: scroll;
  overflow-x: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: none;  
  @media only screen and (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  // ::-webkit-scrollbar {
  //   display:none;
  // }
  &__tag {
    margin: 10px 0;
    
    &__a {
      padding: 6px 18px;
      border-radius: 20px;
      background-color: #858585;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      white-space: nowrap;
  
      &--selected {
        background-color: #BC0010;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}