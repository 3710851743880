@charset "UTF-8";
// ==========================================================================
// レース結果
// ==========================================================================

.tableHeadline {
  margin-bottom: 23px;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 700;
}

.race-ranking {
  &--1 {
    background-color: #fff;
  }

  &--2 {
    color: #fff;
    background-color: #302E2C;
  }

  &--3 {
    color: #fff;
    background-color: #EB5353;
  }

  &--4 {
    color: #fff;
    background-color: #3A8AEC;
  }

  &--5 {
    background-color: #F6E92A;
  }

  &--6 {
    color: #fff;
    background-color: #5C9E00;
  }

  &--7 {
    color: #fff;
    background-color: #FF9900;
  }

  &--8 {
    color: #fff;
    background-color: #EF6EA6;
  }
}


.refund-ranking {
  font-size: 1rem;
  .race-ranking {
    width: 16px;
    height: 16px;
    padding: 3px;
    border-radius: 6px;
    // font-size: 1rem;
    line-height: 1;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 4px;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}



.raceresultTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  &__head {
    font-size: 1.4rem;
    font-weight: 700;
    background-color: #302E2C;

    i {
      margin-left: 3px;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #F4F4F4;
    }
    &:last-child {
      border-bottom: 1px solid #CBCBCA;
    }
  }

  th,td {
    padding: 4px;
    text-align: center;
    white-space: nowrap;
  }

  th {
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  td {
    min-width: 38px;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  &__movie {
    position: relative;

    &::before {
      content: "\f0da";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #24B6F7;
      top: -8px;
      right: 1px;
      transform: rotate(-45deg);
      display: block;
      position: absolute;
    }

    .link-bg {
      @media #{$sp} {
        &::before {
          display: none;
        }
      }
    }
  }
}


// グランドノート
.grandTable {
  width: 100%;
  
  tr {
    border-bottom: 1px solid #CBCBCA;
  }

  th,td {
    text-align: center;
    border-right: 1px solid #CBCBCA;
  }
  
  th {
    color: #fff;
    background-color: #302e2c;
  }

  td {
    &:first-child {
      background-color: #F4F4F4;
    }
  }
}


// 払戻金
.refundTable {
  width: 100%;

  tr {
    border-bottom: 1px solid #CBCBCA;
    // :nth-child(even) {
    //   background-color: #F4F4F4;
    // }
  }
  
  th,td {
    // border-right: 1px solid #CBCBCA;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  th {
    color: #fff;
    background-color: #302e2c;
  }
}

.raceresultBottom {
  margin-bottom: 73px;

  &__inner {
    border-radius: 10px;
    padding: 25px 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  &-grand {
    width: 100%;
    max-width: 557px;
    @media #{$sp} {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  &-refund {
    width: 100%;
    max-width: 337px;
    @media #{$sp} {
      max-width: 100%;
    }
  }
}

// .raceresultBottom-grand {
//   width: 100%;
//   max-width: 557px;
//   border-radius: 10px;
//   padding: 25px 20px;
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
//   @media #{$sp} {
//     margin-bottom: 40px;
//   }
// }

// .raceresultBottom-refund {
//   width: 100%;
//   max-width: 337px;
//   border-radius: 10px;
//   padding: 25px 20px;
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
// }

.raseresultBtn {
  max-width: 300px;
  padding: 15px 0;
}