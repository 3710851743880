@charset "UTF-8";
// ==========================================================================
// Container
// コンテンツ幅
// ==========================================================================
.wrap {
  max-width: 1400px;
  margin: 0 auto;
}

.container {
  // max-width: 100%;
  max-width: 1400px;
}

main {
  padding: 58px 0 0 !important;
  margin-left: 20px;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    margin-left: 0;
  }
  @media #{$sp} {
    padding: 58px 0 0 !important;
    margin-left: 0;
  }

  &.main--w100 {
    width: 100%;
    margin: 0 auto;
  }
  
}

// .l-wrapper {
//   width: 100%;
//   // padding: 0 45px;
//   // padding: 85px 0;
//   padding: 80px 0;
//   background-size: cover;
//   background-position: center;
//   position: relative;
//   // word-wrap: break-word;
//   // overflow-wrap: break-word;
//   @media #{$sp} {
//     // padding: 0 18px 78px;
//   }
// }

// .mainInner {
//   // padding: 0 45px;
//   background-size: cover;
//   background-position: center;
//   @media #{$sp} {
//     // padding: 0 18px 78px;
//   }

  // &--px0 {
  //   @media #{$sp} {
  //     padding: 0 0 78px;
  //   }
  // }

  // &--py0 {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }
// }

.mainInner {
  width: 100%;
  // padding: 0 45px;
  // padding: 85px 0;
  padding: 0 0 80px;
  background-size: cover;
  background-position: center;
  position: relative;
  // word-wrap: break-word;
  // overflow-wrap: break-word;
  @media #{$sp} {
    // padding: 0 18px 78px;
  }

  &--px45 {
    padding-left: 45px;
    padding-right: 45px;
    @media #{$sp} {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  &--py0 {
    padding-bottom: 0;
  }
}

.c-container {
  width: 100%;
  // padding: 0 30px;
  // background-color: #A01D27;
  position: relative;
  // box-sizing: border-box;
  @media #{$sp} {
    // padding: 0 30px;
    // padding: 0 20px;
    box-sizing: border-box;
  }
}

.c-inner {
  // max-width: 1260px;
  max-width: 1030px;
  // padding: 0 47px;
  padding: 0 45px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  @media #{$sp} {
    max-width: 100%;
    padding: 0 18px;
  }

  @media #{$sp4} {
    padding: 0 15px;
  }

  &--px0 {
    padding-left: 0;
    padding-right: 0;
  }
}