.howMainvisual {
  position: relative;

  &__image {
    @media #{$sp} {
      height: 70vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__txt {
    width: 340px;
    height: 54px;
    right: 50px;
    bottom: 66px;
    background-color: #BC0010;
    position: absolute;
    @media #{$sp} {
      width: 277px;
      height: 39px;
      margin: 0 auto;
      top: 68px;
      left: 0;
      right: 0;
    }
    &::before,&::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 54px 23px 0 0;
      top: 0;
      border-color: #BC0010 transparent transparent;
      position: absolute;
      @media #{$sp} {
        border-width: 39px 16px 0 0;
      }
    }

    &::before {
      left: -23px;
      transform: rotate(180deg);
      @media #{$sp} {
        left: -16px;
      }
    }

    &::after {
      right: -23px;
      @media #{$sp} {
        right: -16px;
      }
    }

    span {
      width: 100%;
      height: 100%;
      font-size: 2rem;
      line-height: 54px;
      text-align: center;
      top: -8px;
      left: -10px;
      color: #fff;
      background-color: #302E2C;
      display: block;
      position: absolute;
      z-index: 2;
      @media #{$sp} {
        top: -6px;
        left: -4px;
        font-size: 1.8rem;
        line-height: 38px;
      }

      &::before,&::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 54px 23px 0 0;
        top: 0;
        border-color: #302e2c transparent transparent;
        position: absolute;
        @media #{$sp} {
          border-width: 39px 16px 0 0;
        }
      }

      &::before {
        left: -23px;
        transform: rotate(180deg);
        @media #{$sp} {
          left: -16px;
        }
      }
  
      &::after {
        right: -23px;
        @media #{$sp} {
          right: -16px;
        }
      }
    }
  }
}

.howHeadline {
  // margin-bottom: 70px;
  @media #{$sp} {
    // display: flex;
    // flex-direction: column;
    text-align: center;
  }

  &__ttl {
    margin-right: 87px;
    // margin-bottom: 12px;
    font-size: 3.2rem;
    font-weight: 700;
    @media #{$sp} {
      margin: 0 auto 32px;
      font-size: 2.6rem;
      text-align: center;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1.5px;
      margin-top: 12px;
      background-color: #fff;
      // display: inline-block;
      display: block;
      @media #{$sp} {
        margin-top: 14px;
      }
    }
  }

  &__copy {
    margin-bottom: 20px;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    position: relative;
    @media #{$sp} {
      margin-bottom: 21px;
      font-size: 1.8rem;
    }

    &:before {
      content: "";
      width: calc(100% + 26px);
      height: 20px;
      // left: -14px;
      left: -13px;
      bottom: -5px;
      background-color: rgba(225,225,225,.12);
      display: block;
      position: absolute;
      @media #{$sp} {
        // width: calc(100% + 10px);
        width: calc(100% + 5px);
        left: -5px;
        bottom: -7px;
      }
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      // width: 5px;
      // height: 100%;
      // height: 20px;
      border-style: solid;
      top: 9px;
      // right: -39px;
      right: -20px;
      // border-width: 20px 19px 0 0;
      border-width: 20px 7px 0 0;
      border-color: rgba(225,225,225,.12) transparent transparent;
      position: absolute;
      @media #{$sp} {
        top: 5px;
        right: -7px;
        // border-width: 20px 7px 0 0;
      }
    }
  }

  &__detail {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    @media #{$sp} {
      font-size: 1.3rem;
    }
  }
}





.howImage {
  max-width: 100%;
  @media #{$sp} {
    height: calc(100vh - 58px);
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}


.howListSection {
  padding: 54px 45px;
  @media #{$sp} {
    padding: 60px 18px 68px;
  }
}

.howListItem {
  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 34px;
    }
  }
  &__image {
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    @media #{$sp} {
      margin-bottom: 13px;
    }
  }

  &__ttl {
    margin-bottom: 15px;
    font-size: 2rem;
    font-weight: 700;
    @media #{$sp} {
      margin-bottom: 13px;
      font-size: 1.4rem;
    }
  }

  &__detail {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 19px;
    @media #{$sp} {
      font-size: 1.2rem;
    }
  }
}



.howMovieSection {
  padding: 96px 45px 87px;
  background-color: #302E2C;
  @media #{$sp} {
    padding: 65px 18px 56px;
  }
}

.howMovieImage {
  border-radius: 10px;
  margin-top: 70px;
  overflow: hidden;
  position: relative;
  @media #{$sp} {
    margin-top: 37px;
  }
}

.movieBtn {
  width: 80px;
  height: 80px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../images/movie-play.svg);
    display: block;
  }
}


//  #modal-content {
//   width: 80%;
//   margin: 0;
//   padding: 0;
//   background: #fff;
//   position: fixed;
//   display: none;
//   z-index: 99999;
//  }


.howRacerSection {
  padding: 96px 45px 60px;
  background-size: cover;
  background-position: center;
  background-image: url(../images/how_racer.png);;
  @media #{$sp} {
    padding: 66px 18px 73px;
  }
}

.howRacerWrap {
  margin-top: 55px;
  margin-bottom: 78px;
  @media #{$sp} {
    margin-top: 42px;
    margin-bottom: 84px;
  }
}

.howRacerItem {
  @media #{$sp} {
    // max-width: calc(50% - 10px);
    max-width: 46%;
    padding: 0;
    &:nth-child(n+3) {
      margin-top: 43px;
    }
  }

  &:nth-child(2n+1) {
    @media #{$sp} {
      // margin-right: 20px;
      margin-right: 8%;
    }
  }

  &__image {

  }

  &__ttl {
    height: 36px;
    // bottom: -21px;
    top: -26px;
    // left: 8px;
    left: 0;
    font-size: 1.6rem;
    font-weight: 700;
    background-color: #bc0010;
    display: inline-block;
    position: relative;
    box-shadow: 2px 5px 4px 0 rgba(0,0,0,.3);
    z-index: 2;
    @media #{$sp} {
      height: 27px;
      left: 6px;
      top: -16px;
      font-size: 1.2rem;
    }

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      background-color: #bc0010;
      display: inline-block;
      @media #{$sp} {
        display: none;
      }
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 16px 0 0;
      top: 0;
      border-color: #bc0010 transparent transparent;
      position: absolute;
      @media #{$sp} {
        border-width: 27px 16px 0 0;
      }
    }

    span {
      max-height: 100%;
      // padding: 7px 20px;
      padding: 8.5px 20px;
      left: -5px;
      bottom: 18px;
      background-color: #000;
      position: relative;
      z-index: 2;
      // box-shadow: 2px 5px 4px 0 rgb(0 0 0 / 30%);
      box-shadow: 2px 5px 4px 0 rgba(0,0,0,.3);
      @media #{$sp} {
        padding: 6.5px 15px;
        top: 1.5px;
        left: -6px;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 33px 16px 0 0;
        top: 0;
        right: -16px;
        border-color: #000 transparent transparent;
        position: absolute;
        @media #{$sp} {
          border-width: 25px 16px 0 0;
        }
      }
    }
  }
}

.howBtn {
  max-width: 280px;
  height: 45px;
  padding: 0;
  // margin: 0 auto;
  line-height: 45px;
  @media #{$sp} {
    max-width: 100%;
  }
}


.howNetSection {
  padding: 96px 45px 78px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/how_net.png);
  @media #{$sp} {
    padding: 80px 18px;
  }
}

.howNetWrap {
  margin-top: 45px;
  @media #{$sp} {
    margin-top: 34px;
  }
}

.howNetItem {
  &:first-child {
    margin-bottom: 45px;
  }

  &__image {
    border-radius: 10px;
    margin-bottom: 25px;
    overflow: hidden;
    @media #{$sp} {
      margin-bottom: 28px;
    }
  }

  &__ttl {
    margin-bottom: 16px;
    font-size: 2rem;
    font-weight: 700;
    @media #{$sp} {
      font-size: 1.4rem;
    }
  }

  &__detail {
    margin-bottom: 42px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 19px;
    @media #{$sp} {
      margin-bottom: 32px;
      font-size: 1.2rem;
    }
  }
}




.howStadiumSection {
  padding: 95px 45px 65px;
  @media #{$sp} {
    padding: 65px 18px 70px;
  }
}

.howNetstadiumImage {
  border-radius: 10px;
  margin: 64px auto 55px;
  overflow: hidden;
  @media #{$sp} {
    margin: 34px auto 40px;
  }
}