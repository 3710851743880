@charset "UTF-8";
// ==========================================================================
// Home
// TOPページの設定
// ==========================================================================

// 本日の開催
.todayRaceSection {
  padding: 100px 0;
  background-size: cover;
  // background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/todayrace_bg.jpg);
  @media #{$sp} {
    // padding: 47px 0 59px;
    padding: 7px 0 59px;
  }
}

.todayRaceWrap {
  // max-width: 1024px;
  // max-width: 912px;
  max-width: 922px;
  // padding: 0 45px;
  padding: 0 4.5%;
  margin: 30px auto 0;
  @media only screen and (max-width: 1180px) {
     
  }
  @media #{$sp} {
    max-width: 400px;
    padding: 0 40px;
  }
}

.todayRaceItem {
  // max-width: 297px;
  max-width: 261px;
  width: 31.6%;
  // padding: 0 20px 20px;
  padding: 0 18px 20px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
  @media only screen and (max-width: 1180px) {
    max-width: 210px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 220px;
  }
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
    // padding: 0 16px 20px;
    padding: 0 0 20px;
  }

  &:not(:first-child) {
    margin-left: 2.6%;
    @media #{$sp} {
      margin-left: 0;
    }
  }

  &:not(:last-child) {
    @media #{$sp} {
      margin-bottom: 17px;
    }
  }
 
  &__raceStarttiime {
    margin-top: 22px;
  }

  &__place {
    // width: calc(100% + 40px);
    width: calc(100% + 36px);
    padding: 11px 0;
    // margin-left: -20px;
    margin-left: -18px;
    border-radius: 10px 10px 0 0;
    // font-size: 2rem;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    @media #{$sp} {
      // width: auto;
      margin-left: 0;
      // font-size: 1.4rem;
    }
  }

  &__rank {
    max-width: 100%;
    width: 51%;
    // width: auto;
    // height: 80px;
    margin: 20px auto;
    @media #{$sp} {
      max-width: 75px;
      height: auto;
      margin: 0;
      display: flex;
      align-items: center;
    }

    // img {
    //   width: auto;
    //   height: 100%;
    //   @media #{$sp} {
    //     width: 100%;
    //     height: auto;
    //   }
    // }
  }

  &__date {
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;

    &--mb0 {
      margin-bottom: 0;
    }

    i {
      margin-right: 8px;
      @media #{$sp} {
        margin-right: 5px;
      }
    }
  }

  &__ttl {
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__livetime {
    margin-bottom: 20px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;
    &--mb0 {
      margin-bottom: 0;
    }

    i {
      margin-right: 8px;
    }
    // @media #{$sp} {
    // }
  }

  &__raceNum {
    // width: 60px;
    width: 50px;
    // height: 60px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    // font-size: 4rem;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1;
    @media #{$sp} {
      width: 42px;
      height: 42px;
      font-size: 2.4rem;
    }
    span {
      font-size: 1.6rem;
      @media #{$sp} {
        font-size: 1.1rem;
      }
    }
  }

  &__starttime {
    // height: 60px;
    // height: 56px;
    // padding: 10px 40px;
    // padding: 10px 34px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 30px;
    margin-left: 10px;
    // font-size: 4rem;
    // font-size: 3.1rem;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1;
    @media #{$sp} {
      height: 42px;
      padding: 10px 15px;
      font-size: 2rem;
    }
    span {
      // font-size: 1.6rem;
      font-size: 1.5rem;
      @media #{$sp} {
        font-size: 1.2rem;
      }
    }
  }

  &__deadline {
    margin-bottom: 20px;
  }

  &__live {
    height: 45px;
    padding: 13.5px;
    font-size: 1.4rem;
    // line-height: 45px;
    @media #{$sp} {
      width: calc(100% - 38px);
      height: 40px;
      padding: 14px 0;
      margin: auto;
      font-size: 1.2rem;
      line-height: 1;
    }
  }
}

.todayRaceWrap--1 .todayRaceItem {
  max-width: 45%;
  width: 50%;
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
  }

  .todayRaceTable {
    width: 100%;
  }
}

.todayRaceWrap--2 .todayRaceItem {
  max-width: 45%;
  width: 45%;
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
  }

  .todayRaceTable {
    width: 100%;
  }
}

.todayRaceTable {
  // width: 100%;
  // margin-bottom: 20px;
  margin: 0 auto 15px;
  @media only screen and (max-width: 1180px) {
    display: none;
  }

  &__head {
    background-color: #858585;
  }

  &__ttl {
    padding: 0 4px;
    text-align: center;
  }

  &__cnt {
    padding: 4px 0;
  }
}


// 特設サイト
.homeSpesialSection {
  padding: 80px 70px;
  background-position: center;
  background-image: url(../images/specialcontent_bg.jpg);
  @media #{$sp} {
    padding: 50px 30px 80px;
  }
}

.slideContentWrap {
  max-width: 960px;
  margin: 50px auto 0;
  position: relative;

  button {
    @media #{$sp} {
      display: none !important;
    }
  }
}

.slideContentItem {
  // width: 200px;
  // margin-right: 30px;
  // margin: 0 15px;
  // padding-right: 30px;
  padding: 0 10px;
  outline: 0;

  // &:not(:first-child) {
  //   margin-left: 30px;
  // }

  &__image {
    max-width: 100%;
    max-height: 132px;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  &__ttl {
    // font-size: 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.5;
  }
}


// 総合ニュース
.homeNewsSection {
  // max-width: 1024px;
  // width: 100%;
  // padding: 80px 0;
  // margin: 0 auto;
  padding: 80px 45px 100px;
  @media #{$sp} {
    padding: 72px 0 65px;
  }
}

.homeNewsSection .headline__en {
  margin-left: 9px;
}

.homeNewsInner {
  max-width: 1024px;
  width: 100%;
  // padding: 80px 0;
  margin: 0 auto;
}

.p-homeNews {
  // width: 100%;
}

.p-homeNewsItem {
  padding: 22px 22px 20px 14px;
  border-bottom: 1px solid #dddddd;
  position: relative;
  &__date {
    color: #828282;
  }

  &__ttl {
    margin-left: 30px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    @media #{$sp} {
      margin-left: 0;
      margin-bottom: 12px;
      font-size: 1.3rem;
      line-height: 23px;
    }
  }

  &__link {
    margin-left: auto;
    font-size: 1.2rem;
    font-weight: 700;
    color: #BC0010;
    text-decoration: underline;
    @media #{$sp} {
      right: 22px;
      bottom: 20px;
      position: absolute;
    }
  }
}

// ピックアップ
.homePickupSection {
  padding: 80px 0;
  background-size: cover;
  background-position: cover;
  background-image: url(../images/pickup_bg.jpg);
}

.pickupWrap {
  // max-width: 1024px;
  max-width: 925px;
  margin: 30px auto 0;
}

.pickupItem {
  // max-width: 240px;
  &:nth-child(n+5) {
    margin-top: 30px;
  }

  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 23px;
    }
  }

  &__image {
    // border-radius: 10px;
    margin-bottom: 20px;
    // overflow: hidden;
    @media #{$sp} {
      margin-bottom: 14px;
    }
    img {
      border-radius: 10px;
    }
  }

  &__ttl {
    // font-size: 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    // line-height: 1.25;
    line-height: 1.5;
    @media #{$sp} {
      font-size: 1.3rem;
      line-height: 20px;
    }
  }
}


// キャンペーン,イベント情報,レース情報
.homeInfoSection {
  padding: 80px 20px;
  background-size: cover;
  background-position-x: center;
  background-image: url(../images/homeInfo_bg.jpg);
  @media #{$sp} {
    padding: 80px 40px;
  }
}

.infoBoxContainer {
  // max-width: 1024px;
  max-width: 938px;
  margin: 0 auto;
  @media #{$sp} {
    max-width: 100%;
  }
}

.infoBoxWrap {
  // max-width: 297px;
  // width: calc((100% - 34px) / 3) !important;
  max-width: calc((100% - 34px) / 3) !important;
  padding: 30px 20px 20px;
  border-radius: 10px;
  // box-sizing: border-box;
  @media #{$sp} {
    max-width: 100% !important;
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 17px;
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

.infoBoxHead {
  margin-bottom: 25px;
  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
  }

  &__link {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;
    text-decoration: underline;
  }
}

.infoBoxItem {
  margin-bottom: 10px;
  @media #{$sp} {
    margin-bottom: 14px;
  }
  &__image {
    img {
      border-radius: 10px;
    }
  }

  &__cnt {
    margin-left: 10px;
  }

  &__ttl {
    margin-bottom: 5px;
    font-size: 1.4rem;
    line-height: 1.3;
    @media #{$sp} {
      margin-bottom: 11px;
      font-size: 1.1rem;
    }
  }

  &__date {
    font-size: 1.2rem;
    @media #{$sp} {
      font-size: 1.1rem;
    }
  }
}

// 初めての方へ
.homeBeginnerSection {
  padding: 80px 0;
  @media #{$sp} {
    padding: 15px 18px 80px;
  }
}

.beginnerWrap {
  // max-width: 1024px;
  max-width: 938px;
  margin: 30px auto 0;
}

.beginnerItem {
  max-width: 320px;
  @media #{$sp} {
    width: calc(50% - 10px);
  }
  
  &:nth-child(n+4) {
    margin-top: 40px;
  }
  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 23px;
    }
  }

  &__image {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
  }

  &__ttl {
    // font-size: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    @media #{$sp} {
      font-size: 1.3rem;
    }
  }
}

// お知らせ
.homeAnnounce {
  padding: 90px 68px 20px;
  @media #{$sp} {
    padding: 72px 0 50px;
  }
}

.homeAnnounce .headline__en {
  margin-left: 16px;
}

.p-homeAnnounce {
  margin-bottom: 32px;
  &__item {
    width: calc(50% - 9px);
    padding: 20px 0;
    border-bottom: 1px solid #CBCBCA;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.5;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
      padding: 18.5px 24px;
      line-height: 23px;
    }

    a {
      color: #fff;
    }
  }
}


// YouTube
.homeYoutubeSection {
  padding: 80px 0;
  background-size: cover;
  background-image: url(../images/youtube_bg.jpg);
  @media #{$sp} {
    padding: 50px 18px;
  }
}

.youtubeBtn {
  max-width: 260px;
  height: 45px;
  border-radius: 25px;
  margin: 35px auto 0 ;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 45px;
  color: #fff;
  background-color: #302E2C;
  text-align: center;
  display: block;
  // box-sizing: border-box;
  @media #{$sp} {
    // max-width: 110px;
    max-width: 100%;
    // height: 35px;
    border-radius: 20px;
    margin-top: 40px;
    font-size: 1.2rem;
    // line-height: 35px;
    // color: #302E2C;
    // background-color: #F4F4F4;
  }
}

// 関連リンク
.homeLinkSection {
  padding: 80px 0 55px;
  background-color: #fff;
}


.homeLink {
  max-width: 1024px;
  margin: 30px auto 0;
  @media #{$sp} {
    padding: 0 20px;
  }
  &__item {
    width: 166px;
    margin-bottom: 25px;
    @media #{$sp} {
      width: calc(50% - 7.5px);
    }
    &:not(:first-child) {
      margin-left: 25px;
      @media #{$sp} {
        margin-left: 0;
      }
    }

    &:nth-child(2n+1) {
      margin-right: 15px;
    }

    a {
      display: inline-block;
      @media #{$sp} {
        margin: auto;
      }
    }
  }
}