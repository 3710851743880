.sidebar {
  // max-width: 370px;
  height: calc(100vh - 68px);
  // padding: 80px 20px 0;
  padding: 0 5px;
  padding-top:0;
  // margin-top:68px;
  position: sticky;
  top: 58px;
  padding-top: 0;
  // margin-top: 68px;
  // position: -webkit-sticky;
  position: sticky;
  // top: 68px;
  // height: calc(100vh - 68px);
  @media #{$sp} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 0 10px;
    margin-top: 58px;
    width: 100%;
    background: #fff;
    height: auto;
    overflow-y: scroll;
  }
}

/* Custom Scrollbar using CSS */
.custom-scrollbar-css {
  overflow-y: scroll;
}

/* scrollbar width */
.custom-scrollbar-css::-webkit-scrollbar {
  width: 10px;
}

/* scrollbar track */
.custom-scrollbar-css::-webkit-scrollbar-track {
  background: none;
}

/* scrollbar handle */
.custom-scrollbar-css::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e0e0e0;
}

.sidebarTxt {
  margin-bottom: 10px;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
}

.sidebarGlobalMenu {
  margin-top: 20px;
  // padding: 0 23px;
  margin-bottom: 24px;
  @media #{$sp}  {
    margin-bottom: 0;
  }
  &__item {
    // padding: 24px 0;
    padding: 10px 0;
    // border-bottom: 1px solid #CBCBCA;
    border-bottom: 1px solid #E0E0E0;
    font-size: 1.4rem;
    line-height: 1;
    a {
      padding: 10px 23px;
      color: #302E2C;
      display: block;
      &:hover {
        background: #E0E0E0;
        border-radius: 10px;
        transition: all .3s ease-in-out 0s;
      }
    }
  }
}

.sidebarBox {
  padding: 24px 20px;
  border-radius: 5px;
}

.sidebarBtn {
  a {
    padding: 12px 0;
  }

  &__login {
    margin-bottom: 10px;
  }

  &__signup {
    margin-bottom: 24px;
  }
}

// SNS
.sidebarSnsMenu {
  margin-bottom: 24px;
  &__item {
    // width: 38px;
    // height: 38px;
    // border-radius: 50%;
    // margin-right: 10px;
    &:not(:nth-child(n+4)) {
      margin-bottom: 24px;
    }

    a {
      max-width: 38px;
      max-height: 38px;
    }
  }
}

// 文字サイズ
.fontSizeWrap {
  padding-bottom: 26px;
  margin-bottom: 22px;
  border-bottom: 1px solid #CBCBCA;
}

.fontSize {
  // &__wrap {
  //   padding-bottom: 26px;
  //   margin-bottom: 22px;
  //   border-bottom: 1px solid #CBCBCA;
  // }

  &__item {
    // width: 55px;
    width: calc((100% - 28px) / 3);
    height: 35px;
    text-align: center;
    border-radius: 10px;
    line-height: 1;
    // &:not(:last-child) {
    //   margin-right: 7px;
    // }
    &--small {
      font-size: 1.2rem;
    }
    &--midium {
      font-size: 1.4rem;
    }
    &--large {
      font-size: 1.8rem;
    }

    a {
      line-height: 35px;
      color: #302E2C;
      display: block;
    }
  }
}


.sidebar-contact {
  padding: 12px 0;
  font-size: 1.6rem;
  line-height: 1;
}