@charset "UTF-8";
// ==========================================================================
// Home
// TOPページの設定
// ==========================================================================
.f-footer {
  max-width: 1160px !important;
  // margin: 0 auto;
  padding: 0 !important;
  @media #{$sp} {
    max-width: 100%;
    padding: 0 !important;
  }
}

.f-footerNav {
  max-width: 1024px;
  padding: 80px 35px 88px;
  margin: 0 auto;
  // margin-bottom: 73px;
  @media #{$sp} {
    padding: 0;
  }
}

.f-footerMenuWrap {
  &__chevron {
    // transition: all .3s;
    &--open {
      transform: rotate(90deg);
    }
  }
  @media #{$sp} {
    padding: 17px 20px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
    border-bottom: 1px solid#dddddd;
    @media #{$sp} {
      margin-bottom: 0;
    }
  }
}

.f-footerMenuTtl {
  width: 73px;
  margin-right: 20px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  text-align: right;
  color: #000;
  display: block;
  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
    text-align: left;
  }
  @media #{$sp} {
    width: 100%;
    // padding: 17px 20px;
    // background-color: #F4F4F4;
    text-align: left;
    display: flex;
  }

  i {
    display: none;
    @media #{$sp} {
      margin-left: auto;
      display: block;
    }
  }
}

.f-footerMenu {
  width: 100%;
  margin-bottom: 15px;
  @media #{$sp} {
    display: none;
    margin-top: 15px;
  }
  &__item {
    margin-right: 20px;
    margin-bottom: 15px;
    font-size: 1.3rem;
    line-height: 1;

    a {
      color: #858585;
    }
  }
}

.f-footerBottom {
  padding: 40px 0;
  color: #fff;
  text-align: center;
  @media #{$sp} {
    padding: 22px 20px;
  }
  &__note {
    margin-bottom: 24px;
    font-size: 1.4rem;
    line-height: 1.5;
    @media #{$sp} {
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  &__copyright {
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: .04em;
    @media #{$sp} {
      font-size: 1rem;
    }
  }
}