
.netStadiumMenuWrap {
  // max-width: 983px;
  // padding: 45px 0 40px;
  padding: 45px 0 60px;
  // margin: 0 auto;
  @media #{$sp} {
    padding: 0 0 40px;
  }

  .c-inner {
    @media #{$sp} {
      padding: 0;
    }
  }
}

.netStadiumMenu {
  height: 50px;
  // padding: 12.5px 0;
  // margin: 45px auto 40px;
  border-radius: 10px;
  // background-color: #302e2c;
  display: flex;
  overflow: hidden;
  @media #{$sp} {
    width: 100%;
    height: 45px;
    border-radius: 0;
    // margin: 0 auto 32px;
  }

  &__item {
    width: 20%;
    font-size: 1.3rem;
    line-height: 25px;
    text-align: center;
    background-color: #302e2c;
    transition: all .3s;
    @media #{$sp} {
      font-size: 1.1rem
    }

    &:not(:last-child) {
      // border-right: 0.5px solid #CBCBCA;
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: calc(100% - 25px);
        top: 12.5px;
        right: 0;
        background-color: #CBCBCA;
        display: block;
        position: absolute;
      }
    }

    &:hover {
      background-color: #BA071A;     
    }
    &--selected {
      background-color: #BA071A;     
    }

    a {
      padding: 12.5px 0;
      display: block;
    }
  }
}

.netStadiumSubMenu {
  max-width: calc(100% - 20px);
  padding: 0 20px;
  margin: 0 auto;
  background-color: #F4F4F4;
  justify-content: center;
  left: 0;
  right: 0;
  justify-content: space-between;
  overflow-x: auto;
  word-break: keep-all;
  white-space: nowrap;
  display: none;

  @media #{$sp} {
    max-width: 100%;
    padding: 0 10px;
    white-space: nowrap;
    overflow-x: scroll;
  }

  &--show {
    display: flex;
  }

  &__item {
    padding: 16px 15px 12px;
    border-bottom: 4px solid transparent;
    &:hover {
      border-bottom: 4px solid #BA071A;
    }
    a {
      color: #302E2C;
      font-size: 1.3rem;
    }
    &--selected {
      border-bottom: 4px solid #BA071A;
    }
  }

  &--selected {
    position: relative;
    margin: 0 25px;
    display: flex;
    left: initial;
    right: initial;

    @media #{$sp} {
      margin: 0;
      // display: flex;
      // display: inline-block;
    }
  }
}

.netstadiumHeadline {
  // font-size: 2.6rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  &__todayrace {
    padding-bottom: 31.5px;
    border-bottom: 1px solid #fff;
    margin-bottom: 60px;
    font-size: 2rem;
    color: #fff;
    @media #{$sp} {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 34px;
      font-size: 1.6rem;
    }
  }

  &__other {
    font-size: 3.2rem;
    color: #fff;
    @media #{$sp} {
      font-size: 2.6rem;
    }
    &--en {
      margin-top: 16px;
      font-size: 1.2rem;
      color: #858585;
      display: block;
      @media #{$sp} {
        margin-top: 10px;
        font-size: 1.1rem;
      }
    }    
  }

  &__search {
    font-size: 2.6rem;
  }
}

// .netstadium-todayraceWrap {
//   margin-top: 60px;
// }

.netstadium-todayraceSection {
  padding: 40px 0 90px;
  @media #{$sp} {
    padding: 26px 0;
  }
}

.netstadium-todayrace {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.14);
  &:not(:last-child) {
    margin-bottom: 38px;
    @media #{$sp} {
      margin-bottom: 25px;
    }
  }
}

.netstadium-todayraceLeft {
  width: 58%;
  // padding: 0 11%;
  // padding: 43px 60px 40px
  padding: 30px;
  background-color: #302E2C;
  @media #{$sp} {
    width: 100%;
    padding: 20px 18px 28px;
  }

  &__head {
    padding-bottom: 27px;
    border-bottom: 1px solid #CBCBCA;
    margin-bottom: 24px;
    @media #{$sp} {
      padding-bottom: 22px;
      margin-bottom: 18px;
    }
  }

  &__stadium {
    max-width: 85px;
    min-width: 64px;
    max-height: 65px;
    padding: 22px 10px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    margin-right: 22px;
    // font-size: 2.1rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: #fff;
    @media #{$sp} {
      max-width: 66px;
      min-width: 48px;
      max-height: 51px;
      padding: 17px 8px;
      margin-right: 17px;
      font-size: 1.6rem;
    }
  }

  &__weather {
    margin-left: auto;
    font-size: 6.5rem;
    color: #fff;
    @media #{$sp} {
      font-size: 4.5rem;
    }
  }
}

.netstadium-todayraceLeftLive {
  margin-bottom: 14px;
  color: #fff;
  @media #{$sp} {
    margin-bottom: 10px;
  } 
  &__time {
    // margin-left: auto;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1;
    @media #{$sp} {
      font-size: 1.7rem;
    }
  }

  i {
    font-size: 1.6rem;
    margin-right: 7px;
    @media #{$sp} {
      font-size: 1.1rem;
      margin-right: 6px;
    }
  }
}

.netstadium-todayraceLeftCat {
  &__item {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &--normal {
      color: #fff;
      background-color: #141313;
    }

    &--morning {
      color: #fff;
      background-color: #EB5353;
    }

    &--hakubo {
      color: #fff;
      background-color: #EF6EA6;
    }

    &--nighter {
      color: #fff;
      background-color: #3A8AEC;
    }

    &--midnight {
      color: #fff;
      background-color: #5543B2;
    }
  }
}

.netstadium-todayraceLeftBtn {
  &__item {
    width: calc(50% - 12.5px);
    border-radius: 20px;
    padding: 15px 0;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    background-color: #FFFFFF;
    @media #{$sp} {
      width: calc(50% - 10px);
      padding: 10px 0;
    }
    
    &:nth-child(n+3) {
      margin-top: 17px;
      @media #{$sp} {
        margin-top: 14px;
      }
    }
  }
}

.netstadium-todayraceRight {
  width: 42%;
  // padding: 0 60px;
  padding: 30px;
  background-color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$sp} {
    width: 100%;
    padding: 23px 18px 26.5px;
  }
}

.netstadium-todayraceRightInfo {
  width: 100%;
  &__item {
    padding: 10px 20px;
    border-top: 1px solid #CBCBCA;
    display: flex;
    @media #{$sp} {
      padding: 10px;
    }

    &:last-child {
      border-bottom: 1px solid #CBCBCA;
    }

    p {
      font-size: 1.5rem;
      line-height: 1;
      @media #{$sp} {
        font-size: 1.3rem;
      }
      &:first-child {
        width: 70%;
        font-weight: 700;
      }
      &:last-child {
        width: 30%;
        font-weight: 400;
      }
    }
  }
}

.netstadium-otherSection {
  padding: 72px 0 79px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media #{$sp} {
    padding: 66px auto 90px;
  }
}

.netstadiumContentWrap {
  margin-top: 50px;
}

.nestadiumContent {
  max-width: 454px;
  width: calc(50% - 15px);
  border-radius: 10px;
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
  }

  &:first-child {
    @media #{$sp} {
      margin-bottom: 22px;
    }
  }

  &__image {
    width: 32.8%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    width: 67.2%;
    padding: 23px 22px 21px;
    border-radius: 0 10px 10px 0;
    background-color: #fff;
    @media #{$sp} {
      padding: 16px 14px 24px;
    }
  }
}

.netstadiumContentInfo {
  // padding-left: 10%;
  // width: 82.6%;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 auto 17px;
  line-height: 1;
  background-color: #F4F4F4;
  box-shadow: 0 0 4px 0px rgba(0,0,0,0.14);
  @media #{$sp} {
    padding: 8px 13px;
    margin: 0 auto 12px;
  }
  &__stadium {
    // margin-right: 35px;
    font-size: 1.9rem;
    font-weight: 700;
    @media #{$sp} {
      // margin-right: 30px;
      font-size: 1.6rem;
    }
  }

  &__date {
    // margin-left: 9px;
    margin-left: auto;
    font-size: 1.3rem;
    font-weight: 700;
    @media #{$sp} {
      // margin-left: 6px;
      font-size: 1rem;
    }
  }
}

.nestadiumContentMenu {
  padding: 0 18px;
  @media #{$sp} {
    padding: 0 4px;
  }
  &__item {
    width: 50%;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    &:nth-child(n+3) {
      margin-top: 16px;
      @media #{$sp} {
        margin-top: 14px;
      }
    }
  }
}




.netstadium-searchSection {
  padding: 82px 0 98px;
  color: #fff;
  background-color: #BC0010;
  @media #{$sp} {
    padding: 59px 0 66px;
  }
}

.searchCardWrap {
 margin: 37px auto 63px;
 @media #{$sp} {
   margin: 42px auto 46px;
 }
}

.searchCard {
  width: calc(20% - 16px);
  @media #{$sp} {
    width: calc(50% - 10px);
  }

  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 33px;
    }
  }

  &__image {
    border-radius: 10px;
    margin-bottom: 13px;
    overflow: hidden;
  }

  &__ttl {
    font-size: 1.3rem;
    font-weight: 700;
  }

}

.searchMenuContent {
  // max-width: 455px;
  width: calc(50% - 16px);
  border-top: 1px solid #F4F4F4;
  padding: 20px 22px;
  position: relative;
  @media #{$sp} {
    padding: 18px 12px;
  }
  @media #{$sp} {
    width: 100%;
  }

  &:nth-child(6) {
    border-bottom: 1px solid #F4F4F4;
    @media #{$sp} {
      border-bottom: 0;
    }
  }
  &:last-child {
    border-bottom: 1px solid #F4F4F4;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F4F4F4;
    mix-blend-mode: multiply;
    display: block;
    position: absolute;
    z-index: 0;
  }
  // padding: 20px 4.8%;
  &__head {
    margin-bottom: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    @media #{$sp} {
      margin-bottom: 12px;
    }
  }
}

.searchMenu {
  position: relative;
  z-index: 2;

  &__item {
    font-size: 1.1rem;
    font-weight: 400;
    &:not(:first-child) {
      margin-left: 12px;
      @media #{$sp} {
        margin-left: 10px;
      }
      @media #{$sp4} {
        margin-left: 7px;
      }
      &::before {
        content: "/";
        margin-right: 11px;
        @media #{$sp} {
          margin-right: 10px;
        }
        @media #{$sp4} {
          margin-right: 7px;
        }
      }
    }

    a {
      color: #fff;
    }
  }
}