@charset "UTF-8";
// ==========================================================================
// CALENDAR
// 開催日程ページの設定
// ==========================================================================
.calendarBtnWrap {
  padding-bottom: 48px;
  position: relative;
  @media #{$sp} {
    padding-bottom: 35px;
  }
  
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #302E2C, #302E2C 3px, transparent 3px, transparent 8px);
    background-size: 8px .5px;
    background-repeat: repeat-x;
    position: absolute;
    // top: 0;
    bottom: 0;
    // left: 0;
    // right: 0;
    @media #{$sp} {
      // max-width: calc(100% -36px);
      // margin: 0 auto;
    }
  }
}

.calendarBtn {
  width: 220px;
  height: 60px;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  display: block;
  @media #{$sp} {
    width: 100%;
    max-width: calc(100% - 36px);
    height: 50px;
    margin-left: auto !important;
    margin-right: auto !important;
    line-height: 50px;
  }

  &:not(:first-child) {
    margin-left: 40px;
    @media #{$sp} {
      margin-top: 15px;
      margin-left: 0;
    }
  }
}

.caledarMonth {
  max-width: 464px;
  padding: 10px 0 12px;
  margin: 0 auto;
  line-height: 1;
  @media #{$sp} {
    padding: 13px;
  }
  &__current {
    font-size: 1.8rem;
    font-weight: 700;
    @media #{$sp} {
      font-size: 1.4rem;
    }
  }

  i {
    font-size: 2.2rem;
    color: #302E2C;
    @media #{$sp} {
      font-size: 2rem;
    }
  }
}

.calendarTableWrap {
  @media #{$sp} {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}

.calendarTable {
  text-align: center !important;
  thead {
    th {
      padding: 7px 0;
      font-size: 1.1rem;
      font-weight: 700;
      &:first-child {
        border-radius: 18px 0 0 18px;
        @media #{$sp} {
          border-radius: 0;
        }
      }
      &:last-child {
        border-radius: 0 18px 18px 0;
        @media #{$sp} {
          border-radius: 0;
        }
      }
    }
  }
  
  th,td {
    border-top: 0 !important;
    border-bottom: 0 !important;
    &:not(:first-child) {
      max-width: 26px;
    }
    &:not(:last-child) {
      border-right: 1px solid #dee2e6;
    }
  }

  th {
    // padding-left: 0;
    // padding-right: 0;
    text-align: center;
    @media #{$sp} {
    }
  }

  td {
    padding: 8px 2.5px;
    @media #{$sp} {
    }
  }
}

.calendarStadium {
  &__name {
    padding: 14px 10px !important;
    @media #{$sp} {
      padding: 20px 16px !important;
    }

    span {
      width: 95px;
      height: 80px;
      border-radius: 10px;
      line-height: 80px;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
      background-color: #F4F4F4;
      display: block;
      @media #{$sp} {
        width: 78px;
      }
    }
  }


  // &__cnt {
  //   border-radius: 10px;
  //   margin: 8px 5px;
  //   display: block;
  //   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  //   &--normal {
  //     // background-color: #F8F7C5;
  //     background-color: #efefef !important;
  //   }
  //   &--night {

  //   }
  //   &--sg {
  //     // background-color: #95E6FF;
  //     background-color: #FFC74F !important;
  //   }
  // }
}

.calendarJogai {
  height: 28px;
  border-bottom: 1px solid #CBCBCA;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: #F4F4F4;
  // th {
  //   display: inline-block;
  // }
}

.calendarStadiumCnt {
  // max-width: calc(100% - 8px);
  cursor: pointer;
  // padding: 15px 3px 10px;
  padding: 15px 2.5px 10px;
  // margin: 8px auto;
  border-radius: 10px;
  font-weight: 700;
  // margin: 8px 5px;
  display: block;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  &--normal {
    // background-color: #F8F7C5;
    // background-color: #efefef !important;
    background-color: #FFF360;
  }
  // &--night {
  //   background-color: #00A9D2 !important;
  // }

  &--sg {
    // background-color: #95E6FF;
    background-color: #EABD76 !important;
  }

  &--g1 {
    // background-color: #386b9b;
    background-color: #BEBEBE;
  }

  &--g2 {
    // background-color: #933ED3 !important;
    background-color: #D08658;
  }

  &__grade {
    // margin-bottom: 12px;
    margin-bottom: 7px;
    font-size: 1.2rem;
    line-height: 1;
    display: block;
  }

  &__time {
    padding: 7px 3px;
    border-radius: 5px;
    margin-bottom: 10px;
    // margin-bottom: 5px;
    font-size: 1rem;
    line-height: 1;
    background-color: #fff;
    display: block;
  }

  &__category {
    line-height: 1;
    display: block;
  }

  &__cs {
    font-size: 1rem;
    line-height: 1;
    display: block;
  }
}

#calendarDetailModal {
  .modal-body-remark {
    text-align: right;
  }
}

.calendar-modalWrap {
  max-width: 400px !important;

  .modal-content {
    padding: 20px !important;
  }

  .modal-header {
    padding: 0 0 20px;
  }

  button.close {
    top: -10px;
    right: -10px;
  }
}