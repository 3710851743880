@charset "UTF-8";
// ==========================================================================
// RANKING
// ランキングページのスタイル
// ==========================================================================

.rankingMenu {
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  @media #{$sp} {
    max-width: 100%;
    padding-left: 24px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    // display: inline-block;
    flex-wrap: inherit;
  }
  &__item {
    width: calc((100% - 90px) / 7);
    height: 30px;
    border-radius: 20px;
    margin-bottom: 16px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 30px;
    background-color: #858585;
    text-align: center;
    @media #{$sp} {
      width: auto;
      padding: 0 10px;
    }
    &:not(:nth-child(6n+1)) {
      margin-left: 1.5%;
      @media #{$sp} {
        margin-left: auto;
      }
    }
    &:last-child {
      margin-left: 1.5%;
      @media #{$sp} {
        margin-left: auto;
      }
    }
    &:nth-child(6) {
      margin-right: calc((100% - 90px) / 7);;
      @media #{$sp} {
        margin-right: auto;
      }
    }

    &:not(:first-child) {
      @media #{$sp} {
        margin-left: 12px;
      }
    }
    &--current {
      background-color: #BC0010;
    }
  }
}

.ranking-tabMenuWrap {
  padding-top: 15px;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 27px;
}

.ranking-tabMenu {
  &__item {
    width: 100px;
    // padding-bottom: 8px;
    margin-bottom: -1px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    // &--current {
    //   border-bottom: 3px solid #BC0010;
    //   a {
    //     color: #fff !important;
    //   }
    // }

    a {
      color: #858585;
      transition: all .3s;

      &:hover {
        border: 0;
        color: #fff;
      }

      &.active {
        border-bottom: 3px solid #BC0010;
        color: #fff !important;
      }
    }

    span {
      &.active {
        border-bottom: 3px solid #BC0010;
        color: #fff !important;
      }
    }
  }
}

.rankingWrap {
  padding: 45px 30px 150px;
  margin: 22px auto 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/ranking_bg.png);
  @media #{$sp} {
    padding: 35px 0 100px;
    // margin: 35px auto 100px;
    margin: 35px auto 0;
  }
}

.rankingTtl {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  @media #{$sp} {
    font-size: 1.2rem;
  }

  a {
    color: #fff;
  }
}

.rankingDate {
  font-size: 1.2rem;
  color: #fff;
}

.rankingAll {
  // width: 100%;
}

.rankingSideWrap {
  // max-width: 315px;
  @media #{$sp} {
    max-width: 100%;
  }
}

.rankingSide {
  &:not(:first-child) {
    margin-top: 40px;
  }

  font-size: 1.1rem;
}


.rankingTable {
  width: 100%;
  @media #{$sp} {
    margin-bottom: 35px;
  }

  tr {
    // &:nth-child(n+4) {
    //   td {
    //     background-color: #F4F4F4;
    //   }
    // }
    
    &:not(:last-child) {
      td {
        border-bottom: 1px solid #CBCBCA;
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 10px;
        }
        &:last-child {
          border-radius: 0 0 10px 0;
        }
      }
    }
  }

  th,td {
    min-width: 34px;
    padding: 0 5px;
    font-size: 1.2rem;
    text-align: center;
    // background-color: #fff;
    // border-right: .5px solid #CBCBCA;
    font-weight: 700;
    white-space: nowrap;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  thead {
    th {
      height: 45px;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;
      @media #{$sp} {
        height: 32px;
        font-size: 1rem;
      }
      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
  
  tbody {
    td {
      padding: 0;
      // color: #302E2C;
      background-color: #fff;
      a {
        padding: 10px 0;
        // color: #302E2C;
      }
    }
  }

  

  &__head {
    // background-color: red;
  }
}

.rankingSide .rankingTable td {
  font-weight: 400;
}

.rankingTableCnt {
  &--3 {
    border-bottom: 1px solid #000 !important;
  }
  &__rank {
    height: 45px;
    background-color: #F4F4F4 !important;
    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #fff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
      display: inline-block;
      @media #{$sp} {
        width: 20px;
        height: 20px;
      }
    }

    &--1 {
      span {
        // padding: 6px 8px;
        background: linear-gradient(#f7e3ae 0%, #8e6124 100%);
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24));
      }
    }
    &--2 {
      span {
        background: linear-gradient(#ededed 0%, #a0a0a0 100%);
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24));
      }
    }
    &--3 {
      span {
        background: linear-gradient(#db8d5d 0%, #741f00 100%);
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24));
      }
    }
  }

  &__profile {
    a {
      color: #302E2C;
      display: block;
    }
  }
}

.ranking-comment {
  padding: 0 15px;
  margin-top: 30px;
  font-size: 1.4rem;
  @media #{$sp} {
    margin-top: 0;
  }
}