@charset "UTF-8";
// ==========================================================================
// profile
// プロフィールスタイル
// ==========================================================================

.profile-top {
  margin-bottom: 85px;
  @media #{$sp} {
    margin-bottom: 55px;
  }
}

.profileName {
  height: 36px;
  // padding: 10px 20px;
  // padding: 10px 0;
  margin-bottom: 22px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  // bottom: -15px;
  bottom: 0;
  color: #fff;
  // background-color: #000;
  background-color: #BC0010;
  display: inline-block;
  position: relative;
  box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
  z-index: 2;
  @media #{$sp} {
    height: auto;
    // height: 30px;
    // height: 28px;
    // bottom: -18px;
    // bottom: -22px;
    font-size: 1.3rem;
    line-height: 1.36;
  }

  &::before {
    content: "";
    width: 5px;
    height: 100%;
    background-color: #BC0010;
    display: inline-block;
  }
  
  &::after {
    content: "";
    // width: 100%;
    // height: 100%;
    // bottom: -6px;
    // left: 0;
    // background-color: #BC0010;
    // display: inline-block;
    // position: absolute;
    // z-index: -1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 36px 16px 0 0;
    top: 0;
    border-color: #BC0010 transparent transparent transparent;
    position: absolute;
    @media #{$sp} {
      border-width: 48px 12px 0 0;
    }
  }

  &--sff {
    // width: calc(100% + 80px);
    width: calc(100% + 7px);
    // width: calc(100% - 3px);
    bottom: -24px;
    display: block;
    @media #{$sp} {
      max-width: 100%;
      // width: calc(100% - 18px);
      width: calc(100% - 7px);
      bottom: -16px;
    }

    &::after {
      right: -16px;
      @media #{$sp} {
        border-width: 28px 12px 0 0 !important;
        right: -12px;
      }
    }
  }

  span {
    max-height: 100%;
    // padding: 10px 20px;
    padding: 7px 20px;
    left: -5px;
    bottom: 18px;
    background-color: #000;
    position: relative;
    z-index: 2;
    box-shadow: 2px 5px 4px 0px rgba(0,0,0,0.3);
    @media #{$sp} {
      // width: 100%;
      width: calc(100% - 5px);
      height: 100%;
      padding: 7px 15px;
      // bottom: 15px;
      bottom: 16px;
      display: inline-block;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 16px 0 0;
      top: 0;
      right: -16px;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      @media #{$sp} {
        border-width: 48px 12px 0 0;
        right: -12px;
      }
    }
  }
}

.profileListWrap .col-sm-6 {
  max-width: calc(50% - 10px);
  padding: 24px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    max-width: 100%;
  }

  &:first-child {
    @media #{$sp} {
      margin-bottom: 25px;
    }
  }
}

.profile-graphWrap {
  margin-top: 23px;
}

.profile-graphWrap .col-sm-6 {
  max-width: calc(50% - 13px);
  height: 280px;
  border-radius: 10px;
  margin-bottom: 55px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    max-width: 100%;
  }
}


.profileListTtl {
  padding: 4px 0;
  margin-bottom: 7px;
  font-size: 1.2rem;
}

.profileList {
  padding: 0 15px;
  border-radius: 10px;
  margin-bottom: 7px;
  font-size: 1.2rem;
  display: flex;
  // background-color: #F4F4F4;
  &:nth-child(odd)  {
    background-color: #F4F4F4;
  }

  &__ttl {
    width: 110px;
    // margin-right: 60px;
    font-weight: 700;
  }

  &__cnt {
    width: calc(100% - 110px);
    // padding: 0 15px;
    max-width: 158px;
  }
}

.profile-racerImage {
  max-width: 210px;
  max-height: 210px;
  border-radius: 10px;
  margin-bottom: 23px;
  overflow: hidden;
  @media #{$sp} {
    width: 52%;
    max-width: 180px;
    margin-bottom: 22px;
  }
}


.titlewonTable {
  margin-bottom: 18px;
  @media #{$sp} {
    width: 45%;
    max-width: 170px;
    margin-bottom: 22px;
  }

  td {
    border-bottom: 1px solid #CBCBCA;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }
  th, td  {
    @media #{$sp} {
      padding: 5px 0;
    }
  }
}

.profile-video {
  @media #{$sp} {
    margin-bottom: 26px;
  }
}

.profile-titleTableWrap {
  margin-top: 25px;
  margin-bottom: 60px;
}

.profile-titleTable {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);

  th,td {
    border-bottom: 1px solid #CBCBCA;
    padding: 10px 5px;
  }

  th {
    border-right: 1px solid #CBCBCA;
    text-align: center;
    width: 100px;

    @media #{$sp} {
      padding: 10px 5px;
      width: auto;
    }

    img {
      width: 56px;
      height: auto;
    }
  }

  td {
    font-weight: 700;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }
}


.individual-resultTableWrap {
  max-width: 100%;
  padding: 26px 20px 18px;
  margin-top: 25px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
  box-sizing: border-box;
  @media #{$sp} {
    max-width: calc(100% + 36px);
    width: calc(100% + 36px);
    padding: 0;
    margin-left: -18px;
    border-radius: 0;
    box-shadow: none;
  }

  &--10sou {
    border-bottom: 1px solid #CBCBCA;
  }

  &--90days {
    border-bottom: 1px solid #CBCBCA;
    margin-bottom: 45px;
  }
}

.individual-resultTable {
  width: 100%;
  &__ttl {
    padding: 5px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #BC0010 !important;
    text-align: center;
  }

  tr {
    margin: 8px 0;
    &:nth-child(even) {
      td {
        background-color: #F4F4F4;

        &:first-child {
          border-radius: 10px 0 0 10px;
          @media #{$sp} {
            border-radius: 0;
          }
        }

        &:last-child {
          border-radius: 0 10px 10px 0;
          @media #{$sp} {
            border-radius: 0;
          }
        }
      }
    }
  }

  th,td {
    width: 100px;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  th {
    padding: 5px 0;
    font-weight: 700;
    color: #fff;
    background-color: #302E2C;
  }

  td {
    padding: 8px 0;
    // margin: 8px 0;
    // line-height: 1;
  }
}


.individual-last10ResultTable {
  width: 100%;
  &__ttl {
    padding: 5px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #BC0010 !important;
    text-align: center;
  }

  tr {
    margin: 8px 0;
  }

  th,td {
    width: 100px;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }

  }

  th {
    padding: 5px 0;
    font-weight: 700;
    color: #fff;
    background-color: #302E2C;
  }

  td {
    padding: 8px 0;
    border-bottom: 1px solid #cbcbca;
    // margin: 8px 0;
    // line-height: 1;
  }
}


.individual-last90ResultTable {
  width: 100%;
  &__ttl {
    padding: 5px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #BC0010 !important;
    text-align: center;
  }

  tr {
    margin: 8px 0;
  }

  th,td {
    width: 100px;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }

  }

  th {
    padding: 5px 0;
    font-weight: 700;
    color: #fff;
    background-color: #302E2C;
    min-width: 30px;
  }

  td {
    padding: 8px 0;
    border-bottom: 1px solid #cbcbca;
    // max-width: 22px;
    @media #{$sp} {
      max-width: 22px;
    }
  }

  &__td {
    &__ttl {
      &--weather-good {
        background-color: rgba(36, 182, 247, 0.15);
        font-weight: bold;
      }
      &--weather-wet {
        background-color: rgba(188, 0, 16, 0.15);
        font-weight: bold;
      }
    }
    &--weather-good {
      background-color: rgba(36, 182, 247, 0.1);
    }
    &--weather-wet {
      background-color: rgba(188, 0, 16, 0.1);
    }
  }
}



.individual-assenTableWrap {
  padding: 13px 20px;
  border-radius: 10px;
  margin-top: 25px;
  box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
  @media #{$sp} {
    padding: 0;
  }
}

.individual-assenTable {
  width: 100%;
  tr {
    height: 30px;
    &:not(:last-child) {
      border-bottom: 1px solid #CBCBCA;
    }
  }

  th,td {
    text-align: center;
    padding: 15px 6px;
    @media #{$sp} {
      min-width: 35px;
      font-size: 12px;
    }
    &:not(:last-child) {
      border-right: 1px solid #CBCBCA;
    }
  }

  th {
    // width: 100px;
    background-color: #F4F4F4;
    white-space: nowrap;
  }
}