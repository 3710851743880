.studiumMenu {
  margin-bottom: 36px;
  @media #{$sp} {
    border-bottom: 1px solid #CBCBCA;
    padding-bottom: 4px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      @media #{$sp} {
        // content: "";
        // border-bottom: 1px solid #CBCBCA;
        // width: 100%;
        // height: 1px;
        // background-color: #707070;
        // display: block;
      }
    }
  }

  &__item {
    // border-bottom: 1px solid #CBCBCA;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    @media #{$sp} {
      font-size: 1.4rem;
      display: inline-block;
      white-space: normal;
    }

    &:not(:last-child) {
      margin-right: 40px;
      @media #{$sp} {
        margin-right: 0;
      }
    }

    a {
      color: #302E2C;
      @media #{$sp} {
        padding: 15px 20px;
        display: block;
      }
    }

    &--current {
      position: relative;
      &::after {
        content: "";
        width: calc(100% + 10px);
        height: 2px;
        margin-top: 7px;
        margin-left: -5px;
        background-color: #BC0010;
        display: block;
        @media #{$sp} {
          // width: calc(100% + 8px);
          width: calc(100% - 40px);
          // width: 100%;
          height: 4px;
          margin-top: 0;
          // margin-left: -4px;
          margin-left: 20px;
          // margin-left: 0;
          position: absolute;
          bottom: -4px;
        }
      }
      a {
        color: #BC0010;
      }
    }
  }
}

.studiumMenu__item:hover {
  &::after {
    content: "";
    width: calc(100% + 10px);
    height: 2px;
    margin-top: 7px;
    margin-left: -5px;
    background-color: #BC0010;
    display: block;
    @media #{$sp} {
      height: 0;
    }
  }

  a {
    color: #BC0010;
    @media #{$sp} {
      color: inherit;
    }
  }
}


// 本日の開催
.stadiumRace {
  padding: 45px;
  background-size: cover;
  background-position: center;
  background-image: url(../images/kawaguchi.jpg);
  @media #{$sp} {
    height: 593px;
    padding: 77px 45px;
    display: flex;
  }

  .todayRaceItem {
    max-width: 45%;
    width: 45%;
    height: auto;
    margin-top: auto;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
    }
  }

  .todayRaceTable {
   width: 100%;   
  }
}

.stadiumNewsSection {
  // padding: 80px 45px 95px;
  padding: 0 45px 95px;
  @media #{$sp} {
    padding: 53px 18px 75px;
  }
}

.stadiumNewsWrap {
  max-width: 940px;
  margin: 0 auto;
}

.stadiumNewsItem {
  width: 18.3%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  @media #{$sp} {
    width: calc((100% - 20px) / 2);
  }

  &:not(:last-child) {
    margin-right: 2.125%;
    @media #{$sp} {
      margin-right: 0;
    }
  }

  &:nth-child(2n+1) {
    @media #{$sp} {
      margin-right: 20px;
    }
  }

  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 20px;
    }
  }

  &__ttl {
    padding: 18px 16px 20px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    color: #302E2C;
  }
}


// 場外車券売場
.ticketOfficeSection {
  // padding: 100px 40px;
  padding: 20px 40px 100px;
  background-color: #000;
  @media #{$sp} {
    padding: 73px 18px;
  }
}

.ticketOfficeWrap {
  max-width: 940px;
  margin: 0 auto;
}

.ticketOffice {
  width: calc((100% - 100px) / 5);
  @media #{$sp} {
    width: calc((100% - 19px) / 2);
  }
  &:not(:nth-child(5n+1)) {
    margin-left: 20px;
    @media #{$sp} {
      margin-left: 0;
    }
  }
  &:nth-child(n+6) {
    margin-top: 44px;
    @media #{$sp} {
      margin-top: 0;
    }
  }
  &:nth-child(2n+2) {
    @media #{$sp} {
      margin-left: 19px;
    }
  }
  &:nth-child(n+3) {
    @media #{$sp} {
      margin-top: 21px;
    }
  }

  &__image {
    border-radius: 10px;
    margin-bottom: 22px;
    overflow: hidden;
    @media #{$sp} {
      margin-bottom: 14px;
    }
  }

  &__ttl {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 25px;
    @media #{$sp} {
      font-size: 1.3rem;
    }
  }
}

// アクセス
.stadiumAccessSection {
  // padding: 90px 45px 10px;
  padding: 10px 45px 10px;
  @media #{$sp} {
    padding: 0 18px 73px;
  }
}

.stadiumAccessWrap {
  margin-bottom: 45px;
  @media #{$sp} {
    margin-bottom: 35px;
  }
  iframe {
    max-width: 540px;
    width: 100%;
    height: 360px;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
      height: 225px;
      margin-bottom: 25px;
    }
  }
}

.stadiumAccess {
  // max-width: 360px;
  width: 100%;
  &__head {
    padding: 6px 14px;
    margin-bottom: 14px;
    background-color: #F4F4F4;

    i {
      margin-right: 15px;
    }
  }

  &__cnt {
    padding: 0 12px;
    margin-bottom: 25px;
    &:not(:last-child) {

    }
  }
}

.contactTableWrap {
  max-width: 937px;
  margin: 0 auto;
}

.contactTable {
  // max-width: 907px;
  max-width: 100%;
  width: calc(100% - 30px);
  margin: 0 auto;
  // padding: 0 15px;
  // margin: 0 auto;
  @media #{$sp} {
    width: 100%;
  }
  &__head {
    padding: 14px 34px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    background-color: #F4F4F4;
    @media #{$sp} {
      padding: 9px 17px;
      margin-bottom: 8px;
    }
  }
}

.contactInfo {
  border-bottom: 1px solid #CBCBCA;
  font-size: 1.4rem;
  line-height: 25px;
  @media #{$sp} {
    padding: 19px 0;
    display: block;
  }

  &__head {
    width: 30%;
    padding: 15px 12.5px;
    // font-size: 1.4rem;
    font-weight: 700;
    // line-height: 25px;
    color: #302E2C;
    @media #{$sp} {
      width: 100%;
      padding: 0 16.5px;
      margin-bottom: 15px;
      display: block;
    }
  }

  &__cnt {
    padding: 15px 0;
    // font-size: 1.4rem;
    font-weight: 400;
    @media #{$sp} {
      width: 100%;
      padding: 0 16.5px;
      display: block;
    }
  }
}