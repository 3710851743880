.paginationNav {
  padding-top: 32px;
  margin-bottom: 70px;
  // margin: 60px auto 70px;
  // @media #{$sp} {
  //   margin: 55px auto 45px;
  // }
  @media #{$sp} {
    margin-bottom: 55px;
  }

  &--bt {
    border-top: 1px solid #CBCBCA;
    // padding-top: 32px;
    margin: 60px auto 70px;
    @media #{$sp} {
      margin: 55px auto 45px;
    }
  }

  &__item {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1;
    color: #BC0010;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sp} {
      font-size: 1.1rem;
    }
    
    i {
      width: 32px;
      height: 32px;
      border: 1px solid #BA071A;
      border-radius: 50%;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--prev {
      i {
        margin-right: 12px;
      }
    }

    &--next {
      i {
        margin-left: 12px;
      }
    }
  }

  &__border {
    width: 1px;
    height: 32px;
    margin: 0 27.5px;
    background-color: #707070;
    @media #{$sp} {
      margin: 0 21.5px;
    }
  }
}