@charset "UTF-8";
// ==========================================================================
// Headline
// 見出しのスタイル
// ==========================================================================
.p-homeHeadline {
  // font-size: 2.2rem;
  font-size: 3.2rem;
  font-weight: 700;
  color: #302E2C;
  @media #{$sp} {
    padding: 30px 22px 0;
    font-size: 2rem;
  }

  &__en {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #fff;

    &--left {
      margin-top: 0;
      margin-left: 20px;
      @media #{$sp} {
        margin-left: 25px;
      }
    }
  }
}

.c-headline {
  padding-top: 80px;
  margin-bottom: 48px;
  // font-size: 3.4rem;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .08em;
  @media #{$sp} {
    padding: 40px 23px 0;
    // margin-bottom: 34px;
    margin-bottom: 40px;
    // font-size: 2rem;
    font-size: 2.6rem;
    // line-height: 32px;
  }
  // text-align: center;
  &__en {
    // margin-top: 15px;
    margin-top: 10px;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1em;
    // display: block;
    @media #{$sp} {
      font-size: 1.1rem;
    }
  }

  &--pt76 {
    padding-top: 76px;
    @media #{$sp} {
      // padding-top: 0;
    }
  }

  &--f20 {
    font-size: 2rem;
  }
}

.headline-border {
  border-bottom: 1px solid #CBCBCA;
  padding: 76px 0 64px;
  // margin-bottom: 42px;
  // margin: 0 -18px 43px;
  margin: 0 auto 43px;
  @media #{$sp} {
    // margin: 0 auto 43px;
  }
}