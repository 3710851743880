
.gradeMovieNoteWrap {
  border: 1px solid #BC0010;
  border-radius: 5px;
  padding: 14px;
  margin-bottom: 25px;
}

.gradeMovieNoteWrap li {
  font-size: 10px;
}

.gradeMovieItemWrap {
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3); 
  padding: 30px 24px;
  margin: 30px auto 0;
  
  &__ttl {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  @media only screen and (max-width: 768px) {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 30px auto 0;
  }
}

.gradeMovieRaceTypeColumn {
  &:not(:last-child) {
    border-right: 1px solid #dee2e6;
  }
  @media only screen and (max-width: 768px) {
    &:not(:last-child) {
      border-right: none;
    }
        &:not(:first-child) {
      margin-top: 40px;
    }
  }
}

.gradeMovieRaceType {
  background: linear-gradient(#5D5D5D 0%, #000 100%);
  padding: 10px;
  border-radius: 5px;

  &__ttl {
    color: #fff;
    font-size: 17px;
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 13px;
    }
  }

  img {
    width: 80px;
  }
}

.gradeMovieRaceVideoCard {
  padding: 10px;
  margin-top: 25px;
  background-color: #FAFAFA;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
  border-radius: 5px;

  img {
    border-radius: 5px;
  }

  &__video {
    position: relative;

    &__overlayPlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &__icon {
        font-size: 40px;
        color: #fff;
      }
    }
  }

  &__info {
    margin-top: 8px;
    padding: 10px;

    p {
      font-size: 12px;
    }
  }

  &__raceOnDemand {
    padding: 10px;
    &__ttl {
      font-size: 10px;
      margin-bottom: 5px;
    }

    &__odSelect {
      @media only screen and (max-width: 575px) {
        display: inline-block;
        width: auto;
        vertical-align: middle;
      }
    }
  }
}
