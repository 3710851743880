@charset "UTF-8";
// ==========================================================================
// Single
// シングルスタイル
// ==========================================================================

.p-singleContentWrap {
  padding: 85px 0;
//   p {
//     // padding: 0 12.5px;
//     font-size: 1.8rem;
//     font-weight: 400;
//     line-height: 1.8;
//     letter-spacing: 0.5px;
//     @media #{$sp} {
//       padding: 0;
//       // font-size: 1.2rem;
//     }
//   }
//   img {
//     width: 80%;
//     // margin: 30.5px auto 0;
//     margin: 64px auto;
//     display: block;
//     @media #{$sp} {
//       width: 100%;
//       // margin-top: 27px;
//       margin: 27px auto;
//     }
//   }
}

.p-singleHeadline {
  border-bottom: 1px solid #CBCBCA;
  padding: 0 12.5px 25.5px;
  margin: 0 auto 12.5px;
  // font-size: 1.6rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: .08em;
  @media #{$sp} {
    padding: 0 0 15px;
    margin: 47px auto 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.p-singleHeadWrap {
  padding: 0 12.5px;
  margin-bottom: 16px;
  @media #{$sp} {
    padding: 0;
  }
}

.p-singleHead {
  &__date {
    // margin-left: 12.5px;
    // margin-bottom: 16px;
    font-size: 1.2rem;
    @media #{$sp} {
      margin-left: 0;
      margin-bottom: 13px;
    }
  }

  &__stadium {
    margin-left: 16.5px;
    // margin-bottom: 16px;
    border-radius: 10px;
    padding: 3.5px 13px;
    font-size: 1.2rem;
    line-height: 1;
    color: #fff;
    background-color: #BC0010;
    @media #{$sp} {
      margin-left: 11px;
      margin-bottom: 13px;
    }
  }
}

.p-singleContent {
  padding: 0 12.5px;
  @media #{$sp} {
    padding: 0;
  }

  p {
    // padding: 0 12.5px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.5px;
    @media #{$sp} {
      padding: 0;
      // font-size: 1.2rem;
    }
  }
  img {
    width: 80%;
    // margin: 30.5px auto 0;
    margin: 64px auto;
    display: block;
    @media #{$sp} {
      width: 100%;
      // margin-top: 27px;
      margin: 27px auto;
    }
  }
  
  &__ttl {
    padding: 0 0 25.5px;
    border-bottom: 1px solid #CBCBCA;
    margin-bottom: 12.5px;
    font-size: 1.6rem;
    font-weight: 700;
    @media #{$sp} {
      padding: 0 0 17px;
      font-size: 1.4rem;
    }
  }
  

  // &__txt {
  //   padding: 0 12.5px;
  //   font-size: 1.8rem;
  //   font-weight: 400;
  //   line-height: 1.8;
  //   letter-spacing: 0.5px;
  //   @media #{$sp} {
  //     padding: 0;
  //     font-size: 1.2rem;
  //   }
  // }

  // &__image {
  //   margin-top: 30.5px;
  //   @media #{$sp} {
  //     margin-top: 27px;
  //   }
  // }

  &__btn {
    width: 280px;
    border-radius: 23px;
    margin: 45px auto 0;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    background-color: #BC0010;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
    }
  }
}

.p-singleCta {
  &__txt {
    padding: 12px 20px;
    margin-bottom: 45px;
    font-size: 1.6rem;
    font-weight: 700;
    background-color: #F4F4F4;
    @media #{$sp} {
      padding: 12px 10px;
      font-size: 1.4rem;
      line-height: 24px;
    }
  }

  &__btn {
    width: 280px;
    border-radius: 23px;
    margin: 0 auto 40px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
    background-color: #BC0010;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
      margin-bottom: 45px;
    }
  }

  &__detail {
    max-width: 567px;
    border-radius: 10px;
    padding: 27px 30px;
    margin: 0 auto 48px;
    font-size: 1.4rem;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: #302E2C;
    background-color: #F4F4F4;
    @media #{$sp} {
      max-width: 100%;
      width: 100%;
      padding: 20px 10px;
      margin: 0 auto 45px;
      font-size: 1.2rem;
    }
  }
}


.c-telcenterWrap {
  padding: 35px 0 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 12px 0 rgba(0,0,0,0.2);
}

.c-telcenterTtl {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  i {
    margin-right: 15px;
  }
}

.c-telcenterContentWrap {
  max-width: 567px;
  border-radius: 10px;
  padding: 15px 30px;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #F4F4F4;
  overflow: hidden;
  @media #{$sp} {
    padding: 0;
    border-radius: 0;
    overflow: inherit;
  }
}

.c-telcenterContent {
  line-height: 38px;
  &:not(:last-child) {
    border-bottom: 1px solid #CBCBCA;
  }
  
  &__ttl {
    width: 34%;
    text-align: center;
    background-color: #F4F4F4;
  }

  &__cnt {
    width: 66%;
    text-align: center;
  }
}